import React, { useState, useContext, useEffect } from "react";
import {
  Grid,
  Card,
  CardContent,
  Box,
  Button,
  CircularProgress,
} from "@material-ui/core";
import WizardStep from "../../components/WizardStep/WizardStep";
import PageTitle from "../../components/PageTitle/PageTitle";
import SignatureCanvas from "react-signature-canvas";
import { FormattedMessage } from "react-intl";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Redirect, useParams } from "react-router-dom";
import { GeneralContext } from "../../contexts/GeneralContext";

const Signature = () => {
  const { cid } = useParams();
  const { CIDSignature, setCIDSignature, LngLink } = useContext(GeneralContext);

  const [CanvasRef, setCanvasRef] = useState();
  const [RedirectOn, setRedirectOn] = useState(false);
  const [Submitting, setSubmitting] = useState(false);
  const [Ready, setReady] = useState(false);
  const [Signature, setSignature] = useState();

  useEffect(() => {
      if (CIDSignature && CIDSignature[cid] && CanvasRef) {
          CanvasRef.fromDataURL(CIDSignature[cid]);
          setSignature(CIDSignature[cid]);
          setReady(true);
        }
  }, [CanvasRef]);

  const handleSubmit = () => {
    if (!Signature) return false;
    setCIDSignature({...CIDSignature, [cid]: Signature});
    setRedirectOn(true);
    setSubmitting(true);
  }

  return (
    <Grid container>
      <Grid item xs={12} style={{ width: "100%" }}>
        <WizardStep activeStep={1} />
      </Grid>
      <Grid item xs={12}>
        <PageTitle title="sig.title" description="sig.description" />
      </Grid>
      <Grid item xs={12}>
        <Box p={2} pt={1} pb={1}>
          <Card variant="outlined">
            <CardContent>
              <Grid container>
                <Grid item xs={12}>
                  <SignatureCanvas
                    canvasProps={{
                      className: "sigCanvas",
                      style: {
                        width: "100%",
                        height: "40vh",
                        maxHeight: "15em",
                      },
                    }}
                    ref={(ref) => setCanvasRef(ref)}
                    onEnd={() => {
                      setReady(!CanvasRef.isEmpty());
                      setSignature(CanvasRef.toDataURL());
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Grid container justify="flex-end">
                    <Grid item>
                      <Button
                        className="sigClear"
                        variant="text"
                        onClick={() => {
                          CanvasRef.clear();
                          setReady(!CanvasRef.isEmpty());
                          setSignature();
                        }}
                      >
                        <FormattedMessage id="sig.clear" />
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Grid>
      <Grid item xs={12} className="ButtonWrapper">
        <Box p={2} pt={1}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            disabled={!Ready && !Signature}
            endIcon={<ChevronRightIcon />}
            onClick={handleSubmit}
          >
            <FormattedMessage id="sig.submit" />
          </Button>
          {Submitting && (
            <CircularProgress size={24} className="ButtonProgress" />
          )}
        </Box>
      </Grid>
      {RedirectOn && <Redirect push to={`${LngLink + process.env.REACT_APP_ROOT_PATH_LINK}/checkin/${cid}/verify`} />}
    </Grid>
  );
};

export default Signature;
