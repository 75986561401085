import React, { useContext, useEffect } from "react";
import "./AppHeader.css";
import { createBrowserHistory } from "history";

import {
  Box,
  Menu,
  MenuItem,
  IconButton,
} from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { GeneralContext } from "../../contexts/GeneralContext";
import { Link } from "react-router-dom";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import MenuIcon from "@material-ui/icons/Menu";

const AppHeader = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { doLogout, PosData, PreviousPage, setPreviousPage, LngLink } = useContext(GeneralContext);

  const history = createBrowserHistory();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Box
        display="flex"
        p={1}
        justifyContent={"space-between"}
        alignItems="center"
        className="AppHeader"
      >
        <Box>
          {PreviousPage ? (
            <Link to={PreviousPage}>
              <IconButton aria-label="Back">
                <ChevronLeftIcon />
              </IconButton>
            </Link>
          ) : (
            <IconButton aria-label="Back" onClick={() => history.goBack()}>
              <ChevronLeftIcon />
            </IconButton>
          )}
        </Box>
        <Box>
          {PosData.Logo ? (
            <img src={PosData.Logo} alt={PosData.Title} className="LogoImg" />
          ) : (
            PosData.Title
          )}
        </Box>
        <Box>
          <IconButton
            aria-label="Profile"
            aria-controls="user-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="user-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <Link
              to={LngLink + process.env.REACT_APP_ROOT_PATH_LINK + "/"}
              style={{ textDecoration: "none", color: "black" }}
            >
              <MenuItem onClick={handleClose}>
                <FormattedMessage id="main.mainmenu" />
              </MenuItem>
            </Link>
            <MenuItem onClick={doLogout}>
              <FormattedMessage id="main.logout" />
            </MenuItem>
          </Menu>
        </Box>
      </Box>
      <Box p={1} className="AppHeaderPlaceholder" />
    </div>
  );
};

export default AppHeader;
