import Reco1 from './../assets/img/docTypes/reco_1.jpg';
import Reco2 from './../assets/img/docTypes/reco_2.jpg';
import Reco3 from './../assets/img/docTypes/reco_3.jpg';
import Reco4 from './../assets/img/docTypes/reco_4.jpg';

const recos = [
    {
        id: 1,
        nameId : "recos.1",
        image : Reco1
    },
    {
        id: 2,
        nameId : "recos.2",
        image : Reco2
    },
    {
        id: 3,
        nameId : "recos.3",
        image : Reco3
    },
    {
        id: 4,
        nameId : "recos.4",
        image : Reco4
    }
]

export default recos;