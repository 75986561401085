import axios from "axios";
import React from 'react';
import { toast } from "react-toastify";
import { FormattedMessage } from "react-intl";

axios.defaults.baseURL = process.env.REACT_APP_API2_EP;
let apiEndpoint = process.env.REACT_APP_API2_EP;

axios.interceptors.request.use( 
  (config) => {
    if (
      config.url !== process.env.REACT_APP_LOGIN_EP &&
      config.url !== process.env.REACT_APP_POS_INFO_EP &&
      localStorage.getItem("token")
    ) {
      config.headers.Authorization = "Bearer " + localStorage.getItem("token");
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    if (response.data.response === 0) throw response.data.message ? response.data.message : 'Error general';
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log('response status', error.response.status);
      if (error.response.data.response !== 0) toast.error(<FormattedMessage id={`error.${error.response.status}`} />);
      if (error.response.data.response === 0) toast.error(error.response.data.message);
      
    } else {
      toast.error('Ha ocurrido un error desconocido');
    }
    console.error("Http Interceptor Error", error);
    return Promise.reject(error);
  }
);

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  apiEndpoint: apiEndpoint,
}; 