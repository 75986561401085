import React, { createContext, useEffect, useState } from "react";
import AuthService from "../services/AuthService";
import { toast } from "react-toastify";
import httpService from "../services/HttpService";
import { FormattedMessage } from "react-intl";
import docTypes from "../constants/docTypes";
import CountriesList from "../constants/countries";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { reportErrorToNewRelic } from "../helpers/newRelic";

export const GeneralContext = createContext();

const GeneralContextProvider = props => {
  const [UserData, setUserData] = useState();
  const [Authenticated, setAuthenticated] = useState(false);
  const [PosData, setPosData] = useState({});
  const [ProductData, setProductData] = useState({});
  const [CountriesData, setCountriesData] = useState({ List: CountriesList });
  const [Loading, setLoading] = useState(true);
  const [GeneralError, setGeneralError] = useState(false);
  const [CIDData, setCIDData] = useState();
  const [MenuData, setMenuData] = useState();
  const [CurrentPos, setCurrentPos] = useState();
  const [PaxsData, setPaxsData] = useState();
  const [LocalPaxData, setLocalPaxDataInternal] = useState({});
  const [ReviewMode, setReviewMode] = useState(false);
  const [Validating, setValidating] = useState(0);
  const [DocTypes, setDocTypes] = useState(docTypes);
  const [CIDSignature, setCIDSignatureInternal] = useState();
  const [SIRECities, setSIRECities] = useState();
  const [BookingReady, setBookingReady] = useState([]);
  const [CurrentLng, setCurrentLng] = useState(props.language);
  const [LngLink, setLngLink] = useState(
    CurrentLng !== "es" ? `/${CurrentLng}` : ""
  );
  const [PreviousPage, setPreviousPage] = useState();

  const history = useHistory();

  useEffect(() => {
    initialize();
  }, []);

  async function initialize() {
    fetchPosData().then(res => {
      preloadAuth(res);
    });
  }

  const setLocalPaxData = data => {
    localStorage.setItem(
      `LocalPaxData_${CIDData.IDInbox}`,
      JSON.stringify(data)
    );
    setLocalPaxDataInternal(data);
  };
  const setCIDSignature = data => {
    localStorage.setItem(
      `CIDSignature_${CIDData.IDInbox}`,
      JSON.stringify(data)
    );
    setCIDSignatureInternal(data);
  };

  const generalError = () => {
    toast.error(<FormattedMessage id="main.generalError" />);
    setGeneralError(true);
    history.push("/app/error");
    setLoading(false);
    const pos = localStorage.getItem("CurrentPos");
    console.log("POS", pos);
    localStorage.clear();
    if (pos) localStorage.setItem("CurrentPos", pos);
  };

  async function getCountriesData() {
    let countryData = await fetchData("CountriesData", CurrentLng);
    if (countryData) console.log("CountryData", countryData);
    if (countryData.Response === 1 || countryData.response === 1)
      setCountriesData(countryData);
  }

  async function cancelBookingFn() {
    if (!CIDData.IDInbox) throw new Error("No CID provided");
    let ep = `${process.env.REACT_APP_API2_EP}${process.env.REACT_APP_CANCEL_BOOKING_EP}${CIDData.IDInbox}`;
    return httpService.post(ep, {}).catch(err => {
      reportErrorToNewRelic("cancelBookingFn", err);
    });
  }

  async function createEmptyPax() {
    let ep = `${process.env.REACT_APP_API2_EP}${process.env.REACT_APP_PAX_CREATE}`;
    let newPaxObj = {
      hotel_company_id: CIDData.empresa,
      cid: CIDData.IDInbox
    };
    return httpService
      .post(ep, newPaxObj, { "Content-Type": "application/json" })
      .then(res => {
        if (!res.data) throw new Error("Corrupt or empty response");
        return res.data;
      })
      .catch(err => {
        if (err.response.status === 401) {
          generalError();
          window.location.href = `${LngLink}${
            LngLink + process.env.REACT_APP_ROOT_PATH_LINK
          }/`;
        }
        throw new Error(err);
      });
  }

  async function submitPaxData(paxId) {
    if (!paxId) throw new Error("PaxId Missing");
    let ep = `${process.env.REACT_APP_API2_EP}${process.env.REACT_APP_PAX_UPDATE_EP1}${CIDData.IDInbox}${process.env.REACT_APP_PAX_UPDATE_EP2}${paxId}`;
    return httpService
      .put(ep, LocalPaxData[paxId], { "Content-Type": "application/json" })
      .then(res => {
        if (!res.data) throw new Error("Corrupt or empty response");
        return res.data;
      })
      .catch(err => {
        reportErrorToNewRelic("submitPaxData", err);
        if (err?.response?.status === 401) {
          generalError();
          window.location.href = `${LngLink}${
            LngLink + process.env.REACT_APP_ROOT_PATH_LINK
          }/`;
        }
        throw new Error(err);
      });
  }

  async function fileUpload(side, file, paxId) {
    if (side !== "frente" && side !== "dorso") return false;
    if (!file) return false;
    if (!paxId) return false;
    const url = `${process.env.REACT_APP_API2_EP}${process.env.REACT_APP_DOCS_ANALYZE}${paxId}`;
    const formData = new FormData();
    formData.append("image", file, { filename: `${side}.jpg` });
    formData.append("idcardfront", side);
    const config = {
      headers: {
        "content-type": "multipart/form-data"
      }
    };
    return httpService
      .post(url, formData, config)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        if (err.response.status === 401) {
          generalError();
          window.location.href = `${LngLink}${
            LngLink + process.env.REACT_APP_ROOT_PATH_LINK
          }/`;
        }
        throw new Error(err);
      });
  }

  async function fetchData(dataType, ...args) {
    let ep = "";
    let lsString = "";
    let lsStore = true;
    switch (dataType) {
      case "MenuData":
        if (!args[1]) throw new Error("Missing ProductID in fetchMenuData");
        if (!args[0]) throw new Error("Missing CID in fetchMenuData");
        ep = `${process.env.REACT_APP_API2_EP}${process.env.REACT_APP_MENU_EP}?cid=${args[0]}&empresa=${args[1]}&market=true&type=WebApp`;
        lsString = `MenuData_${args[1]}`;
        break;
      case "CIDData":
        if (!args[0]) throw new Error("Missing CID in fetchCIDData");
        ep = `${process.env.REACT_APP_API2_EP}${process.env.REACT_APP_BOOKING_INFO_EP}`;
        lsString = `CIDData_${args[0]}`;
        break;
      case "PosInfo":
        if (!args[0]) throw new Error("Missing POS in fetchPosInfo");
        ep = `${process.env.REACT_APP_API2_EP}${process.env.REACT_APP_POS_INFO_EP}${args[0]}`;
        lsString = `PosInfo`;
        break;
      case "ProductData":
        if (!args[0]) throw new Error("Missing POS in fetchProductData");
        ep = `${process.env.REACT_APP_API2_EP}${process.env.REACT_APP_PRODUCT_INFO_EP}${args[0]}`;
        lsString = `ProductData`;
        break;
      case "CountriesData":
        if (!args[0]) args[0] = "es";
        ep = `${process.env.REACT_APP_APIOLD_EP}${process.env.REACT_APP_COUNTRIES_LIST_EP}?lng=${CurrentLng}`;
        lsString = `CountriesData`;
        break;
      case "PolicyData":
        if (!CurrentLng) CurrentLng = "es";
        ep = `${process.env.REACT_APP_API2_EP}${process.env.REACT_APP_POLICIES_INFO_EP}${args[0]}?lng=${CurrentLng}`;
        lsString = `PolicyData_${args[0]}`;
        break;
      case "PaxsData":
        if (!args[0]) throw new Error("Missing CID in fetchPaxsData");
        ep = `${process.env.REACT_APP_API2_EP}${process.env.REACT_APP_PAXS_EP}${args[0]}`;
        lsString = `PaxsData`;
        lsStore = false;
        break;
      case "SIRECities":
        ep = `${process.env.REACT_APP_API2_EP}${process.env.REACT_APP_SIRE_CITIES_EP}`;
        lsString = `SIRECities`;
        break;
      default:
        throw new Error("Undefined DataType");
    }
    if (!ep) throw new Error("Undefined DataType");
    let lsData = JSON.parse(localStorage.getItem(lsString));
    console.log("lsdata", lsData);
    if (lsData) {
      console.log(
        "lsdata diff",
        moment().diff(moment(lsData.cacheTime), "days")
      );
      if (
        !lsData.cacheTime ||
        !moment(lsData.cacheTime).isValid() ||
        moment().diff(moment(lsData.cacheTime), "days") > 7
      )
        lsData = null;
      if (!lsData) console.log("lsData reset > 7 days");
    }
    //console.log("LSDATA " + dataType, lsData);
    //console.log("preHttpService Tk", localStorage.getItem("token"));
    return lsData && !(lsData.response === 0)
      ? lsData
      : httpService
          .get(ep)
          .then(res => {
            if (!res.data) throw new Error("Corrupt or empty " + dataType);
            if (
              lsStore &&
              !(res.data.Response === 0) &&
              !(res.data.response === 0)
            ) {
              res.data.cacheTime = moment().format();
              localStorage.setItem(lsString, JSON.stringify(res.data));
              if (lsString === "ProductData") {
                localStorage.setItem(lsString, JSON.stringify(res.data.info));
                console.log("ProductData", res.data.info);
                return res.data.info;
              }
            }
            return res.data;
          })
          .catch(err => {
            reportErrorToNewRelic(dataType, err);
            if (err.response.status === 401 || err.response.status === 500) {
              generalError();
              window.location.href = `${LngLink}${
                LngLink + process.env.REACT_APP_ROOT_PATH_LINK
              }/`;
            }
            if (dataType !== "PolicyData") throw new Error(err);
          });
  }

  async function fetchDomains() {
    let lsData = JSON.parse(localStorage.getItem("PxDomains"));
    if (lsData) console.log("LSDATA getdomains");
    return lsData
      ? lsData
      : httpService
          .get(
            process.env.REACT_APP_APIOLD_EP +
              process.env.REACT_APP_GET_DOMAINS_EP
          )
          .then(res => {
            if (!res.data || !res.data.List || !res.data.List.length)
              throw new Error("Corrupt or empty getDomains");
            localStorage.setItem("PxDomains", JSON.stringify(res.data));
            return res.data;
          })
          .catch(err => {
            throw new Error(err);
          });
  }

  async function fetchPosData() {
    try {
      let productData = JSON.parse(localStorage.getItem("ProductData"));
      if (productData) {
        console.log(
          "productData Diff",
          moment().diff(moment(productData.cacheTime), "days")
        );
        if (
          !productData.cacheTime ||
          !moment(productData.cacheTime).isValid() ||
          moment().diff(moment(productData.cacheTime), "days") > 7
        )
          productData = null;
      }
      let posData = JSON.parse(localStorage.getItem("PosInfo"));
      if (posData) {
        console.log(
          "posData Diff",
          moment().diff(moment(posData.cacheTime), "days")
        );
        if (
          !posData.cacheTime ||
          !moment(posData.cacheTime).isValid() ||
          moment().diff(moment(posData.cacheTime), "days") > 7
        )
          posData = null;
      }
      if (posData && productData) {
        setPosData(posData);
        setProductData(productData);
        window.productData = productData;
        console.log("AppData loaded from LS");
        return { posData, productData };
      }
      let urlparams = new URLSearchParams(window.location.search);
      let pos = "";
      if (urlparams.get("pos")) pos = urlparams.get("pos").trim();
      let getDomains = await fetchDomains();
      console.log(getDomains);
      let hotelDemo = false;
      if (window.location.host.indexOf("hoteldemo.com.ar") !== -1) {
        hotelDemo = true;
        pos = window.location.host.split(".")[0].toLowerCase();
      }
      getDomains.List.forEach(domain => {
        if (
          (pos &&
            typeof domain.Pos === "string" &&
            domain.Pos.toLowerCase() === pos.toLowerCase()) ||
          window.location.host === domain.Domain ||
          (domain.AlternativeDomains &&
            domain.AlternativeDomains.indexOf(window.location.host) !== -1) ||
          (hotelDemo &&
            pos &&
            typeof domain.Pos === "string" &&
            domain.Pos.toLowerCase() === pos)
        ) {
          pos = domain.Pos;
        }
      });
      if (!pos) pos = CurrentPos;
      if (!pos) pos = localStorage.getItem("CurrentPos");
      if (!pos) {
        reportErrorToNewRelic("fetchPosData", "POS not defined");
        generalError();
        throw new Error("POS not defined");
      }
      setCurrentPos(pos);
      localStorage.setItem("CurrentPos", pos);
      posData = await fetchData("PosInfo", pos);
      setPosData(posData);
      if (posData.DefaultProductID) {
        productData = await fetchData("ProductData", posData.DefaultProductID);
        setProductData(productData);
        window.productData = productData;
      } else {
        productData = {};
        console.warn("No default product ID for this POS");
      }

      return { posData, productData };
    } catch (err) {
      console.error("error fetchPosData2", err);
      generalError();
    }
  }

  async function preloadAuth(appdata) {
    let token = localStorage.getItem("token");
    //console.log("LSTk", token);
    let userData = JSON.parse(localStorage.getItem("UserData"));
    let urlParams = new URLSearchParams(window.location.search);
    console.log("queryParams", window.location.search);
    let paramsCid = urlParams.get("cid");
    let paramsLastName = urlParams.get("lastname");
    let paramsCompany = urlParams.get("c");

    if (paramsCid && paramsLastName && paramsCompany) {
      try {
        // limpiamos los parametros por si el pelotudo del hotelero les mete basura
        console.log("Logging in", paramsCid, paramsLastName, paramsCompany);

        paramsCid = decodeURIComponent(paramsCid);
        paramsLastName = decodeURIComponent(paramsLastName);
        paramsCompany = decodeURIComponent(paramsCompany);

        // remove any special characters from paramsCompany and paramsCid
        paramsCompany = paramsCompany.replace(/[^0-9]/g, "");
        paramsCid = paramsCid.replace(/[^0-9]/g, "");

        let authData = await doAuth({
          reserva: paramsCid.trim(),
          apellido: paramsLastName.trim(),
          companyid: Number(paramsCompany.trim())
        });
        console.log("AuthData", authData);
        token = authData.auth.token;
        localStorage.setItem("token", token);
        userData = authData.userData;
      } catch (error) {
        console.error("General Error on Preload Auth from Querystring");
        console.error(error);
        generalError();
      }
    }

    if (!token || !userData) {
      console.warn("No token or userdata / not logged in");
      setAuthenticated(false);
      setLoading(false);
      return false;
    }
    if (userData) setUserData(userData);
    setAuthenticated(true);
    //console.log("FetchCidDataPreloadAuth");
    localStorage.removeItem(`CIDData_${userData.CID}`);
    let cidInfo = await fetchData("CIDData", userData.CID);
    if (cidInfo.finish) setBookingReady([...BookingReady, cidInfo.IDInbox]);
    if (cidInfo.policy_id) {
      let policyInfo = await fetchData("PolicyData", cidInfo.policy_id);
      cidInfo.policyInfo = policyInfo;
    }
    setCIDData(cidInfo);

    const localPaxData = JSON.parse(
      localStorage.getItem(`LocalPaxData_${userData.CID}`)
    );
    if (localPaxData) setLocalPaxDataInternal(localPaxData);
    const localCIDSignature = JSON.parse(
      localStorage.getItem(`CIDSignature_${userData.CID}`)
    );
    if (localCIDSignature) setCIDSignatureInternal(localCIDSignature);
    getCountriesData();
    getSireCities();
    setLoading(false);

    return true;
  }

  async function confirmBooking() {
    if (!CIDSignature[CIDData.IDInbox]) {
      console.error("no hay firmaaaaaa", CIDSignature, CIDData);
      toast.error("NO HAY FIRMA");
      return false;
    }
    const url = `${process.env.REACT_APP_API2_EP}${process.env.REACT_APP_CONFIRM_BOOKING}`;
    const formData = new FormData();
    const blob = await (await fetch(CIDSignature[CIDData.IDInbox])).blob();
    formData.append("image", blob);
    const config = {
      headers: {
        "content-type": "multipart/form-data"
      }
    };
    return httpService
      .post(url, formData, config)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        if (err.response.status === 401) {
          generalError();
          window.location.href = `${LngLink}${
            LngLink + process.env.REACT_APP_ROOT_PATH_LINK
          }/`;
        }
        throw new Error(err);
      });
  }

  const doLogout = () => {
    localStorage.removeItem("token");
    setAuthenticated(false);
    localStorage.clear();
    history.push("/app/login");
    toast.success("Sesión cerrada con éxito");
  };

  async function getSireCities() {
    let sireCities = await fetchData("SIRECities");
    if (sireCities) {
      setSIRECities(sireCities);
      console.log("SIRECities", sireCities);
    }
  }

  async function doAuth(data, fetchMenu) {
    let auth = await AuthService.loginBooking(data);
    if (!auth || !auth.token) {
      toast.error(<FormattedMessage id="error.loginData" />);
      throw new Error(auth.message);
    }
    const userData = {
      CID: data.reserva,
      LastName: data.apellido,
      CompanyID: data.companyid
    };
    if (auth.response == 0)
      throw new Error("No existe ninguna reserva para ese apellido");
    if (auth.token) localStorage.setItem("token", auth.token);
    //console.log("Token", localStorage.getItem("token"));
    setUserData(userData);
    //console.log("FetchCidDataDoAuth");
    localStorage.removeItem(`CIDData_${data.reserva}`);
    let cidInfo = await fetchData("CIDData", data.reserva);
    if (cidInfo.policy_id) {
      let policyInfo = await fetchData("PolicyData", cidInfo.policy_id);
      cidInfo.policyInfo = policyInfo;
    }
    setCIDData(cidInfo);
    if (cidInfo) setAuthenticated(true);
    console.warn("doAuth", auth);
    getCountriesData();
    return { auth, userData };
  }

  async function doRequestMagicLink(data) {
    const url = `${process.env.REACT_APP_API2_EP}${process.env.REACT_APP_MAGIC_LINK}`;
    return httpService.post(url, data).catch(err => {
      reportErrorToNewRelic("doRequestMagicLink", err);
    });
  }

  async function fetchProductData(productId) {
    try {
      let productData = await fetchData("ProductData", productId);
      if (!productData || Object.keys(productData).length === 0) {
        throw new Error("Invalid or empty product data");
      }
      setProductData(productData);
      return productData;
    } catch (error) {
      console.error("Error fetching product data:", error);
      toast.error(<FormattedMessage id="error.fetchProductData" />);
      return null;
    }
  }

  return (
    <GeneralContext.Provider
      value={{
        Authenticated,
        Loading,
        GeneralError,
        ProductData,
        PosData,
        CIDData,
        UserData,
        MenuData,
        setMenuData,
        fetchData,
        setPaxsData,
        PaxsData,
        LocalPaxData,
        setLocalPaxData,
        setCIDData,
        CIDData,
        doAuth,
        doLogout,
        ReviewMode,
        setReviewMode,
        fileUpload,
        submitPaxData,
        Validating,
        setValidating,
        DocTypes,
        CIDSignature,
        setCIDSignature,
        confirmBooking,
        BookingReady,
        setBookingReady,
        PreviousPage,
        setPreviousPage,
        CurrentLng,
        LngLink,
        getCountriesData,
        CountriesData,
        cancelBookingFn,
        createEmptyPax,
        doRequestMagicLink,
        SIRECities,
        fetchProductData,
        setAuthenticated
      }}
    >
      {props.children}
    </GeneralContext.Provider>
  );
};

export default GeneralContextProvider;
