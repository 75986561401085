import React, { useState, useContext, useEffect, useRef } from "react";
import {
  Grid,
  Typography,
  Button,
  CircularProgress,
  Box,
  Divider
} from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { Redirect } from "react-router-dom";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { Alert, AlertTitle } from "@material-ui/lab";
import { GeneralContext } from "../../contexts/GeneralContext";
import { toast } from "react-toastify";
import moment from "moment";
import Jimp from "jimp/es";
import { reportErrorToNewRelic } from "../../helpers/newRelic";

const PhotoUpload = props => {
  const {
    LocalPaxData,
    setLocalPaxData,
    fileUpload,
    PaxsData,
    setPaxsData,
    fetchData,
    CIDData,
    LngLink
  } = useContext(GeneralContext);
  const [RedirectOn, setRedirectOn] = useState(false);
  const [Submitting, setSubmitting] = useState(false);
  const [Files, setFiles] = useState({ front: null, back: null });
  const [Previews, setPreviews] = useState({ front: null, back: null });
  const [Loading, setLoading] = useState(false);
  const frontInput = useRef();
  const backInput = useRef();
  const [LoadingPreviews, setLoadingPreviews] = useState({
    front: false,
    back: false
  });
  useEffect(() => {
    console.log("useeffect paxdata paxform");
    if (!props.paxId) return;
    if (!PaxsData || !PaxsData.list) return;
    let currentLocalPax = LocalPaxData[props.paxId];
    if (!currentLocalPax) {
      let currentPax = PaxsData.list.find(pax => pax.paxid == props.paxId);
      if (!currentPax) {
        toast.error(<FormattedMessage id="paxForm.noData" />);
        return;
      }
      setLocalPaxData({ ...LocalPaxData, [props.paxId]: currentPax });
    }
    setLoading(false);
  }, [PaxsData]);

  useEffect(() => {
    if (!PaxsData) {
      setLoading(true);
      fetchData("PaxsData", CIDData.IDInbox)
        .then(res => {
          console.log("PAXDATA", res);
          setPaxsData(res);
        })
        .catch(err => {
          toast.error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      console.log("PaxData already loaded on a previous screen");
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    let prev = { ...Previews };
    let hasDocs =
      LocalPaxData[props.paxId] &&
      LocalPaxData[props.paxId].documents &&
      LocalPaxData[props.paxId].documents.length;
    if (
      hasDocs &&
      LocalPaxData[props.paxId].documents[0] &&
      LocalPaxData[props.paxId].documents[0].url
    )
      prev.front = LocalPaxData[props.paxId].documents[0].url;
    if (
      hasDocs &&
      LocalPaxData[props.paxId].documents[1] &&
      LocalPaxData[props.paxId].documents[1].url
    )
      prev.back = LocalPaxData[props.paxId].documents[1].url;

    let newFiles = { ...Files };

    let sides = ["front", "back"];

    let promises = [];
    // download previews and set them as blobs
    sides.forEach(side => {
      if (prev[side]) {
        // set origin header to avoid cors error
        promises.push(
          fetch(prev[side])
            .then(res => res.blob())
            .then(blob => {
              newFiles[side] = blob;
            })
            .catch(err => {
              console.log(err);
            })
        );
      }
    });

    setLoadingPreviews({ front: true, back: true });

    Promise.all(promises)
      .then(() => {
        setFiles(newFiles);
        console.log("useeffect paxdata paxform", prev, newFiles);
        setPreviews(prev);
        setLoadingPreviews({ front: false, back: false });
      })
      .catch(err => {
        console.error(err);
        reportErrorToNewRelic("promises", err);
      });
  }, [LocalPaxData]);

  const handleChange = (e, type) => {
    let file_size = e.target.files[0].size;
    if (file_size > 8e6) {
      toast.error(<FormattedMessage id="photo.fileTooLarge" />);
      return false;
    }
    setLoadingPreviews({ ...LoadingPreviews, [type]: true });

    let reader = new FileReader();
    let file = e.target.files[0];
    reader.readAsDataURL(file);
    reader.onload = () => {
      setLoadingPreviews({ front: false, back: false });
      setFiles({ ...Files, [type]: file });
      setPreviews({
        ...Previews,
        [type]: reader.result
      });
    };
  };

  const toTitleCase = str =>
    str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });

  async function processPhoto(side) {}

  async function submitDocs() {
    let processedFiles = { front: null, back: null };
    let reader = new FileReader();
    reader.readAsArrayBuffer(Files.front);
    reader.onloadend = function () {
      Jimp.read(reader.result).then(image => {
        console.log("jimp", image);
        if (image.getWidth() > 1500) image.resize(1500, Jimp.AUTO);
        if (image.getHeight() > 1500) image.resize(Jimp.AUTO, 1500);
        image.quality(40);
        image.getBufferAsync(Jimp.MIME_JPEG).then(buffer => {
          processedFiles.front = new Blob([buffer]);
          console.log("ProcessedFiles", processedFiles);
          if (!props.currentDocType.hasBack) {
            submitPhotos(processedFiles);
            return;
          }
          let readerBack = new FileReader();
          readerBack.readAsArrayBuffer(Files.back);
          readerBack.onloadend = function () {
            Jimp.read(readerBack.result).then(image => {
              console.log("jimp", image);
              if (image.getWidth() > 1500) image.resize(1500, Jimp.AUTO);
              if (image.getHeight() > 1500) image.resize(Jimp.AUTO, 1500);
              image.quality(40);
              image.getBufferAsync(Jimp.MIME_JPEG).then(buffer => {
                processedFiles.back = new Blob([buffer]);
                console.log("SetProcessedFiles2", processedFiles);
                submitPhotos(processedFiles);
              });
            });
          };
        });
      });
    };
  }

  async function submitPhotos(processedFiles) {
    try {
      let front = props.currentDocType.hasFront
        ? await fileUpload("frente", processedFiles.front, props.paxId)
        : true;
      let back = props.currentDocType.hasBack
        ? await fileUpload("dorso", processedFiles.back, props.paxId)
        : true;
      if (!front || !back) throw new Error("submiterror");
      let sides = [front, back];
      console.log("sides", sides);
      let currentPaxData = { ...LocalPaxData[props.paxId] };
      let paxd = await fetchData("PaxsData", CIDData.IDInbox);
      console.log("PAXDATA", paxd);
      setPaxsData(paxd);
      let currentPax = paxd.list.find(pax => pax.paxid == props.paxId);
      currentPaxData.documents = currentPax.documents;
      let erred = false;
      let newFiles = { ...Files };
      let newPreviews = { ...Previews };
      sides.forEach((side, index) => {
        if (side.error || side.response == 0) {
          let errorCode = index === 0 ? "front" : "back";
          console.error("Error processing img", side, errorCode, index);
          newPreviews = { ...newPreviews, [errorCode]: null };
          newFiles = { ...newFiles, [errorCode]: null };
          if (errorCode === "front") frontInput.current.value = "";
          if (errorCode === "back") backInput.current.value = "";
          erred = true;
          toast.error(
            <FormattedMessage
              id={`photo.errors.${errorCode}`}
              defaultMessage="Ha ocurrido un error desconocido"
            />
          );
        }
        if (side.result && !side.error) {
          if (
            side.result.expiry &&
            moment(side.result.expiry) &&
            moment(side.result.expiry) > moment()
          ) {
            if (
              side.result.documentNumber &&
              side.result.documentNumber !== ""
            ) {
              if (side.result.documentNumber)
                currentPaxData.idnumber = side.result.documentNumber;
              if (side.result.firstName)
                currentPaxData.firstname = toTitleCase(side.result.firstName);
              if (side.result.lastName)
                currentPaxData.lastname = toTitleCase(side.result.lastName);
              if (side.result.nationality_full)
                currentPaxData.nationality = side.result.nationality_full;
              if (side.result.nationality_full)
                currentPaxData.country = side.result.nationality_full;
              if (side.result.dob && moment(side.result.dob))
                currentPaxData.birthdate = moment(side.result.dob).format(
                  "YYYY-MM-DD"
                );
              if (side.result.nationality_full)
                currentPaxData.nationality = side.result.nationality_full;
              console.log("currentdata", currentPaxData);
            }
          }
        }
      });
      if (erred) {
        setFiles(newFiles);
        setPreviews(newPreviews);
        throw "fail";
      }
      setLocalPaxData({ ...LocalPaxData, [props.paxId]: currentPaxData });
      toast.success(<FormattedMessage id="photo.success" />);
      setSubmitting(false);
      setRedirectOn(true);
      return "Exito";
    } catch (err) {
      setSubmitting(false);
      if (err !== "fail") {
        toast.error(
          <FormattedMessage
            id={`photo.errors.${err}`}
            defaultMessage="Ha ocurrido un error desconocido"
          />
        );
      }
    }
  }

  const handleSubmit = () => {
    setSubmitting(true);

    submitDocs().catch(err => {
      setSubmitting(false);
      if (err !== "fail") {
        toast.error(
          <FormattedMessage
            id={`photo.errors.${err}`}
            defaultMessage="Ha ocurrido un error desconocido"
          />
        );
      }
    });
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          {props.currentDocType.hasFront && (
            <Grid item xs={12}>
              <Box pt={2} pb={2}>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <Box
                      display="flex"
                      p={1}
                      justifyContent="center"
                      alignItems="center"
                    >
                      {LoadingPreviews.front ? (
                        <CircularProgress />
                      ) : (
                        <img
                          src={
                            Previews.front
                              ? Previews.front
                              : props.currentDocType.frontExample
                          }
                          style={{ width: "100%" }}
                        />
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="h6">
                      <strong>
                        <FormattedMessage id="photo.front" />
                        {Previews.front && (
                          <CheckCircleOutlineIcon
                            htmlColor="#4DD824"
                            fontSize="small"
                            style={{
                              verticalAlign: "text-bottom",
                              marginLeft: "5px"
                            }}
                          />
                        )}
                      </strong>
                    </Typography>
                    <Typography
                      gutterBottom
                      variant="body2"
                      color="textSecondary"
                    >
                      {LoadingPreviews.front ? (
                        <FormattedMessage id="main.loading" />
                      ) : (
                        <FormattedMessage
                          id={props.currentDocType.frontTextId}
                        />
                      )}
                    </Typography>

                    <label htmlFor="front-upload">
                      <Button
                        variant="outlined"
                        disabled={LoadingPreviews.front || LoadingPreviews.back}
                        color={Previews.front ? "inherit" : "primary"}
                        size="small"
                        component="span"
                      >
                        <CloudUploadIcon />{" "}
                        <Box pl={1}>
                          <FormattedMessage
                            id={
                              Previews.front
                                ? "photo.switchFront"
                                : "photo.uploadFront"
                            }
                          />
                        </Box>
                      </Button>
                    </label>
                    <input
                      ref={frontInput}
                      style={{ display: "none" }}
                      accept="image/*"
                      id="front-upload"
                      type="file"
                      onChange={e => handleChange(e, "front")}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          )}
          {props.currentDocType.hasFront && props.currentDocType.hasBack && (
            <Grid item xs={12}>
              <Divider />
            </Grid>
          )}
          {props.currentDocType.hasBack && (
            <Grid item xs={12}>
              <Box pt={2} pb={2}>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <Box
                      display="flex"
                      p={1}
                      justifyContent="center"
                      alignItems="center"
                    >
                      {LoadingPreviews.back ? (
                        <CircularProgress />
                      ) : (
                        <img
                          src={
                            Previews.back
                              ? Previews.back
                              : props.currentDocType.backExample
                          }
                          style={{ width: "100%" }}
                        />
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="h6">
                      <strong>
                        <FormattedMessage id="photo.back" />
                        {Previews.back && (
                          <CheckCircleOutlineIcon
                            htmlColor="#4DD824"
                            fontSize="small"
                            style={{
                              verticalAlign: "text-bottom",
                              marginLeft: "5px"
                            }}
                          />
                        )}
                      </strong>
                    </Typography>
                    <Typography
                      gutterBottom
                      variant="body2"
                      color="textSecondary"
                    >
                      {LoadingPreviews.back ? (
                        <FormattedMessage id="main.loading" />
                      ) : (
                        <FormattedMessage
                          id={props.currentDocType.backTextId}
                        />
                      )}
                    </Typography>

                    <label htmlFor="back-upload">
                      <Button
                        variant="outlined"
                        disabled={LoadingPreviews.front || LoadingPreviews.back}
                        color={Previews.back ? "inherit" : "primary"}
                        size="small"
                        component="span"
                      >
                        <CloudUploadIcon />{" "}
                        <Box pl={1}>
                          <FormattedMessage
                            id={
                              Previews.back
                                ? "photo.switchBack"
                                : "photo.uploadBack"
                            }
                          />
                        </Box>
                      </Button>
                    </label>
                    <input
                      ref={backInput}
                      style={{ display: "none" }}
                      accept="image/*"
                      id="back-upload"
                      type="file"
                      onChange={e => handleChange(e, "back")}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          )}
        </Grid>
      </Grid>
      {!(
        (!Files.front && props.currentDocType.hasFront) ||
        (!Files.back && props.currentDocType.hasBack)
      ) ? (
        <Grid item xs={12}>
          <Box pt={1} pb={2}>
            <Alert severity="success">
              <AlertTitle>
                <FormattedMessage id="photo.readyTitle" />
              </AlertTitle>
              <FormattedMessage id="photo.ready" />
            </Alert>
          </Box>
        </Grid>
      ) : (
        ""
      )}
      <Grid item xs={12} className="ButtonWrapper">
        <Button
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          disabled={
            Submitting ||
            (!Files.front && props.currentDocType.hasFront) ||
            (!Files.back && props.currentDocType.hasBack)
          }
          endIcon={Submitting ? null : <ChevronRightIcon />}
          onClick={handleSubmit}
        >
          <FormattedMessage
            id={!Submitting ? "photo.submit" : "photo.submitting"}
          />
        </Button>
        {Submitting && (
          <CircularProgress size={24} className="ButtonProgress" />
        )}
      </Grid>
      {RedirectOn ? (
        <Redirect
          push
          to={`${LngLink + process.env.REACT_APP_ROOT_PATH_LINK}/checkin/${
            props.cid
          }/paxs/${props.paxId}`}
        />
      ) : (
        ""
      )}
    </Grid>
  );
};

export default PhotoUpload;
