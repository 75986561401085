import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { GeneralContext } from "../../contexts/GeneralContext";
import HotelInfo from "../../components/HotelInfo/HotelInfo";
import {
  Grid,
  Card,
  Typography,
  Box,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  CircularProgress,
  Avatar
} from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { picsUrl } from "../../helpers/helpers";

const MenuPage = () => {
  const { CIDData, MenuData, setMenuData, fetchData, ProductData, LngLink } =
    useContext(GeneralContext);

  useEffect(() => {
    fetchData("MenuData", CIDData.IDInbox, ProductData.id)
      .then(res => {
        if (res.data && res.data.response === 0) return;
        setMenuData(res);
      })
      .catch(err => {
        console.error(err);
      });
  }, []);
  return (
    <Grid container direction="column">
      <Grid item style={{ width: "100%" }}>
        <HotelInfo />
      </Grid>
      <Grid item>
        <Box p={3}>
          <Typography variant="h6" align="center">
            <strong>
              <FormattedMessage id="menu.welcome" />
            </strong>
          </Typography>
          <Typography variant="subtitle2" align="center">
            <FormattedMessage id="menu.description" />
          </Typography>
        </Box>
      </Grid>
      <Grid item>
        <Box pl={2} pr={2}>
          {MenuData && MenuData.list && MenuData.list.length ? (
            MenuData.list.map(menuItem => (
              <Box mb={1} key={menuItem.id}>
                <Link
                  to={`${LngLink + process.env.REACT_APP_ROOT_PATH_LINK}${
                    menuItem.appurl
                  }/${CIDData.IDInbox}`}
                  style={{ textDecoration: "none" }}
                >
                  <Card variant="outlined" style={{ listStyle: "none" }}>
                    <ListItem button>
                      <ListItemAvatar>
                        <Avatar
                          imgProps={{ style: { objectFit: "contain" } }}
                          style={{ overflow: "visible" }}
                          alt={menuItem.name}
                          src={picsUrl(menuItem.iconurl, 80, 0)}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={menuItem.name}
                        secondary={menuItem.description}
                      />
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          color="primary"
                          aria-label="Ingresar"
                        >
                          <ChevronRightIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </Card>
                </Link>
              </Box>
            ))
          ) : (
            <Box display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default MenuPage;
