import React, { memo, useContext, useEffect, useMemo, useState } from "react";
import { GeneralContext } from "../../../contexts/GeneralContext";
import {
  Grid,
  Box,
  Typography,
  Card,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  CircularProgress,
  Hidden,
  Button
} from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import { toast } from "react-toastify";
import { Link, Redirect, useParams } from "react-router-dom";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CheckIcon from "@material-ui/icons/Check";
import AddIcon from "@material-ui/icons/Add";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import WizardStep from "../../../components/WizardStep/WizardStep";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { Alert, AlertTitle } from "@material-ui/lab";

const Paxs = () => {
  const {
    CIDData,
    PaxsData,
    setPaxsData,
    fetchData,
    LngLink,
    createEmptyPax,
    ProductData
  } = useContext(GeneralContext);
  const [Loading, setLoading] = useState(true);
  const [CompletedPaxs, setCompletedPaxs] = useState(0);
  const [Submitting, setSubmitting] = useState(false);
  const [CreatingPax, setCreatingPax] = useState(false);
  const intl = useIntl();
  let { cid } = useParams();

  async function addNewEmptyPax() {
    console.log("currentPaxsData", PaxsData);
    setCreatingPax(true);
    let newPax = await createEmptyPax();
    console.log("newPax", newPax);
    setCreatingPax(false);
    fetchPaxData();
  }

  const fetchPaxData = () => {
    setPaxsData(null);
    fetchData("PaxsData", CIDData.IDInbox)
      .then(res => {
        console.log("PAXDATA", res);
        let completedPaxs = 0;
        res.list.forEach(pax => {
          if (pax.staterate === "complete") completedPaxs++;
        });
        setCompletedPaxs(completedPaxs);
        setPaxsData(res);
      })
      .catch(err => {
        console.error(err);
        toast.error(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    document.title = intl.formatMessage({
      id: "paxs.pageTitle"
    });
    fetchPaxData();
  }, []);

  const cidPaxs = useMemo(() => {
    let paxs = !isNaN(Number(CIDData.paxs)) ? Number(CIDData.paxs) / 100 : 0;
    if (!paxs) {
      paxs = CIDData.adults + CIDData.childs + CIDData.babies;
    }
    return paxs;
  }, [CIDData]);

  const paxAdded = useMemo(() => {
    return PaxsData ? PaxsData.list.length : 0;
  }, [PaxsData]);

  return (
    <Grid container>
      <Grid item xs={12} style={{ width: "100%" }}>
        <WizardStep activeStep={0} />
      </Grid>
      <Grid item xs={12}>
        <PageTitle
          previousPage={
            LngLink + process.env.REACT_APP_ROOT_PATH_LINK + `/checkin/${cid}`
          }
          title="paxs.title"
          description="paxs.description"
        />
      </Grid>
      <Grid item xs={12}>
        <Box pl={2} pr={2} pt={1} display="flex" justifyContent="center">
          {PaxsData ? (
            <Box
              display="flex"
              flexDirection="column"
              style={{ width: "100%" }}
            >
              {PaxsData?.list?.map(pax => (
                <Box mb={1} key={pax.paxid}>
                  <Link
                    to={`${
                      LngLink + process.env.REACT_APP_ROOT_PATH_LINK
                    }/checkin/${CIDData.IDInbox}/paxs/${pax.paxid}`}
                    style={{ textDecoration: "none" }}
                  >
                    <Card variant="outlined" style={{ listStyle: "none" }}>
                      <ListItem button>
                        <ListItemAvatar>
                          <Avatar
                            style={
                              pax.staterate === "complete"
                                ? {
                                    border: "1px solid #4DD82433",
                                    backgroundColor: "rgb(237, 247, 237)",
                                    color: "#4DD824"
                                  }
                                : {}
                            }
                            alt={pax.firstname}
                          >
                            {pax.staterate === "complete" ? (
                              <CheckIcon />
                            ) : (
                              <HourglassEmptyIcon />
                            )}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            pax.lastname && pax.firstname ? (
                              `${pax.firstname} ${pax.lastname}`
                            ) : pax.ReservationHolder ||
                              pax.reservationholder ? (
                              <FormattedMessage id="paxs.reservationHolder" />
                            ) : (
                              <FormattedMessage id="paxs.companion" />
                            )
                          }
                          secondary={
                            pax.staterate === "complete" ? (
                              <span>
                                <FormattedMessage id="paxs.complete" />
                              </span>
                            ) : (
                              <span>
                                <FormattedMessage id="paxs.pending" />
                              </span>
                            )
                          }
                        />
                        <ListItemSecondaryAction>
                          <Hidden smUp>
                            <IconButton
                              edge="end"
                              color="primary"
                              aria-label="Ingresar"
                            >
                              <ChevronRightIcon />
                            </IconButton>
                          </Hidden>
                          <Hidden xsDown>
                            <Button color="primary">
                              <FormattedMessage id="paxs.goComplete" />{" "}
                              <ChevronRightIcon />
                            </Button>
                          </Hidden>
                        </ListItemSecondaryAction>
                      </ListItem>
                    </Card>
                  </Link>
                </Box>
              ))}
              {ProductData.company_feature_flags.contacts_v2 &&
              paxAdded < cidPaxs ? (
                <Grid item xs={12}>
                  <Box className="ButtonWrapper">
                    <Button
                      variant="outlined"
                      color="primary"
                      size="large"
                      fullWidth
                      disabled={CreatingPax}
                      endIcon={<AddIcon />}
                      onClick={() => {
                        addNewEmptyPax();
                      }}
                    >
                      <FormattedMessage id="paxs.addNewEmptyPax" />
                    </Button>
                    {CreatingPax && (
                      <CircularProgress size={24} className="ButtonProgress" />
                    )}
                  </Box>
                </Grid>
              ) : (
                ""
              )}
              <Box pt={1} style={{ width: "100%", boxSizing: "border-box" }}>
                <Typography
                  variant="overline"
                  align="center"
                  color="textPrimary"
                  component="div"
                  style={{ width: "100%", boxSizing: "border-box" }}
                >
                  <strong>
                    <FormattedMessage
                      id="paxs.completed"
                      values={{
                        completed: CompletedPaxs,
                        total: PaxsData.list.length
                      }}
                    />
                  </strong>
                </Typography>
              </Box>
            </Box>
          ) : Loading ? (
            <Box display="center">
              <CircularProgress color="primary" />
            </Box>
          ) : (
            "Error"
          )}
        </Box>
      </Grid>

      {PaxsData &&
      CompletedPaxs >= PaxsData.list.length &&
      (!ProductData.company_feature_flags.contacts_v2 ||
        (PaxsData && CompletedPaxs >= cidPaxs)) ? (
        <Grid item xs={12}>
          <Box pt={2} pl={2} pr={2}>
            <Alert severity="success">
              <AlertTitle>
                <FormattedMessage id="paxs.readyTitle" />
              </AlertTitle>
              <FormattedMessage id="paxs.ready" />
            </Alert>
          </Box>
        </Grid>
      ) : (
        ""
      )}

      {ProductData.company_feature_flags.contacts_v2 &&
      PaxsData &&
      paxAdded < cidPaxs ? (
        <Grid item xs={12}>
          <Box pt={2} pl={2} pr={2}>
            <Alert severity="warning">
              <AlertTitle>
                <FormattedMessage id="paxs.missingPaxsTitle" />
              </AlertTitle>
              <FormattedMessage
                id="paxs.missingPaxs"
                values={{
                  completed: CompletedPaxs,
                  total: Number(CIDData.paxs / 100)
                }}
              />
            </Alert>
          </Box>
        </Grid>
      ) : (
        ""
      )}
      {(PaxsData && CompletedPaxs >= cidPaxs && cidPaxs > 0) ||
      (PaxsData && CompletedPaxs >= PaxsData.list.length) ? (
        <Grid item xs={12}>
          <Box p={2} className="ButtonWrapper">
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              disabled={Submitting}
              endIcon={<ChevronRightIcon />}
              onClick={() => {
                setPaxsData(null);
                setSubmitting(true);
              }}
            >
              <FormattedMessage id="paxs.submit" />
            </Button>
          </Box>
        </Grid>
      ) : (
        ""
      )}
      {Submitting && (
        <Redirect
          push
          to={`${LngLink + process.env.REACT_APP_ROOT_PATH_LINK}/checkin/${
            CIDData.IDInbox
          }/signature`}
        />
      )}
    </Grid>
  );
};

export default memo(Paxs);
