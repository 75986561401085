import React, { useContext, useState, useEffect } from "react";
import {
  Grid,
  Box,
  Card,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  CircularProgress,
  CardContent
} from "@material-ui/core";
import WizardStep from "../../components/WizardStep/WizardStep";
import PageTitle from "../../components/PageTitle/PageTitle";
import { GeneralContext } from "../../contexts/GeneralContext";
import { useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import PhotoRecos from "../../components/PhotoRecos/PhotoRecos";
import PhotoUpload from "../../components/PhotoUpload/PhotoUpload";

const DocumentStages = () => {
  const { DocTypes } = useContext(GeneralContext);
  let { id, type, stage, cid } = useParams();
  const [CurrentDocType, setCurrentDocType] = useState();

  useEffect(() => {
    let cdt = DocTypes.find((dt) => dt.docType === type);
    setCurrentDocType(cdt);
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} style={{ width: "100%" }}>
        <WizardStep activeStep={0} />
      </Grid>
      <Grid item xs={12}>
        <PageTitle
          title="docUpload.title"
          description="docUpload.description"
        />
      </Grid>
      <Grid item xs={12}>
        {CurrentDocType ? (
          <Box p={2} pt={0}>
            <Card variant="outlined" style={{ listStyle: "none" }}>
              <ListItem>
                <ListItemAvatar>
                  <Avatar
                    imgProps={{ style: { objectFit: "contain" } }}
                    style={{ overflow: "visible" }}
                    alt={CurrentDocType.name}
                    src={CurrentDocType.iconAsset}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <FormattedMessage
                      id={CurrentDocType.nameId}
                      defaultMessage={CurrentDocType.defaultName}
                    />
                  }
                  secondary={
                    <FormattedMessage
                      id={CurrentDocType.descriptionId}
                      defaultMessage={CurrentDocType.defaultDescription}
                    />
                  }
                />
              </ListItem>
              <CardContent>
                  {stage === 'recos' && <PhotoRecos paxId={id} docType={type} cid={cid} />}
                  {stage === 'upload' && <PhotoUpload paxId={id} docType={type} currentDocType={CurrentDocType} cid={cid}/>}
               </CardContent>
            </Card>
          </Box>
        ) : (
          <CircularProgress />
        )}
      </Grid>
    </Grid>
  );
};

export default DocumentStages;
