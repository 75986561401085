import React, { useContext, useState } from "react";
import "./HotelInfo.css";
import { Box, Grid, CircularProgress, Typography } from "@material-ui/core";
import { GeneralContext } from "../../contexts/GeneralContext";
import { picsUrl } from "../../helpers/helpers";
import { reportErrorToNewRelic } from "../../helpers/newRelic";

const HotelInfo = props => {
  const { ProductData, PosData, CIDData, fetchProductData } =
    useContext(GeneralContext);
  const [localProductData, setLocalProductData] = useState(ProductData);

  // If product data is an empty object and we have a product ID, fetch and update the data
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        if (
          Object.keys(localProductData).length === 0 &&
          CIDData?.productolid
        ) {
          const bookingProductData = await fetchProductData(
            CIDData.productolid
          );
          if (bookingProductData) {
            setLocalProductData(bookingProductData);
          }
        }
      } catch (error) {
        console.error("Error fetching product data", error);
        reportErrorToNewRelic("fetchData", error);
      }
    };
    fetchData();
  }, [localProductData, CIDData, fetchProductData]);

  if (Object.keys(localProductData).length === 0) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%"
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  const decodeStr = str => {
    if (typeof str !== "string") return str;
    let parser = new DOMParser();
    let dom = parser.parseFromString(
      "<!doctype html><body>" + str,
      "text/html"
    );
    return dom.body.textContent;
  };

  return (
    <Box p={3} className="HotelInfo">
      <Grid container spacing={1} alignItems="center">
        <Grid item xs>
          <Grid
            container
            direction="column"
            alignItems="flex-start"
            justify="center"
          >
            <Grid item>
              <Typography align="left" variant="h6">
                {decodeStr(ProductData.additional_data.title)}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                color="textSecondary"
                align="left"
                variant="subtitle2"
              >
                {ProductData.direccion}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                color="textSecondary"
                align="left"
                variant="subtitle2"
              >
                {ProductData.provincia + ", " + ProductData.pais}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                color="textSecondary"
                align="left"
                variant="subtitle2"
              >
                {PosData.Phone}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            className="HotelPic"
          >
            {ProductData ? (
              <img
                src={picsUrl(ProductData.foto_tapa, 160, 160)}
                alt={decodeStr(ProductData.additional_data.title)}
              />
            ) : (
              <CircularProgress />
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HotelInfo;
