import React, { useState, useContext, useMemo } from "react";
import "./WizardStep.css";
import {
  Box,
  Grid,
  Typography,
  Stepper,
  Step,
  StepButton,
} from "@material-ui/core";

import { FormattedMessage } from "react-intl";
import { GeneralContext } from "../../contexts/GeneralContext";
import { Redirect } from "react-router-dom";

const WizardStep = (props) => {
  const { CIDData, LngLink} = useContext(GeneralContext);
  const [RedirectStep, setRedirectStep] = useState();

  //cambie por una asignacion de useMemo porque no se estaba usando el setSteps, solo se estaba asignando el valor
  const steps = useMemo(() => [
    {
      step: 0,
      name: "Datos de los huéspedes",
      url: `/checkin/${CIDData.IDInbox}/paxs`,
      label: "Datos",
    },
    {
      step: 1,
      name: "Firma digital",
      url: `/checkin/${CIDData.IDInbox}/signature`,
      label: "Firma",
    },
    {
      step: 2,
      name: "Revisión y confirmación",
      url: `/checkin/${CIDData.IDInbox}/verify`,
      label: "Confirmar",
    },
  ], [CIDData]);

  return (
    <Box p={3} className="WizardStep">
      <Grid container spacing={1} alignItems="center" justify="space-between">
        <Grid item xs={6} md={6}>
          <Typography variant="h6" style={{ fontSize: "16px" }} align="left">
            <strong>
              <FormattedMessage id="wizard.checkin.title" />
            </strong>
          </Typography>
        </Grid>
        <Grid item xs={6} md={6}>
          <Stepper
            nonLinear
            alternativeLabel
            style={{ padding: "0px" }}
            activeStep={props.activeStep}
          >
            {steps.map((step, index) => (
              <Step key={step.step}>
                  <StepButton
                  disabled={props.activeStep < step.step && !CIDData.finish}
                    onClick={() => setRedirectStep(step.step)}
                    completed={props.activeStep >= step.step}
                  ></StepButton>
              </Step>
            ))}
          </Stepper>
        </Grid>
      </Grid>
      {RedirectStep || RedirectStep === 0 ? (
        <Redirect
          to={LngLink + process.env.REACT_APP_ROOT_PATH_LINK + steps[RedirectStep].url}
          push
        />
      ) : null}
    </Box>
  );
};

export default WizardStep;
