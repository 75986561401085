import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { GeneralContext } from "../../contexts/GeneralContext";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { Authenticated, Loading, LngLink, GeneralError } =
    useContext(GeneralContext);
  console.log({ Authenticated, Loading, LngLink, GeneralError });

  return (
    <Route
      {...rest}
      render={props => {
        if (Authenticated && !Loading) {
          if (props.location.pathname === "*/") {
            return <Redirect to={LngLink + "/app"} />;
          }
          if (GeneralError) {
            return <Redirect to={LngLink + "/app/error"} />;
          }
          return <Component {...props} />;
        } else if (!Loading) {
          return (
            <Redirect
              to={{
                pathname: LngLink + "/app/login"
              }}
            />
          );
        }
        return null;
      }}
    />
  );
};

export default PrivateRoute;
