import React, { useContext, useEffect, useState } from "react";
import "./LoginPage.css";
import { GeneralContext } from "../../contexts/GeneralContext";
import {
  Grid,
  Box,
  Typography,
  Divider,
  TextField,
  Button,
  Container,
  CircularProgress
} from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { picsUrl } from "../../helpers/helpers";
import { Skeleton } from "@material-ui/lab";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

const LoginPage = () => {
  const {
    ProductData,
    PosData,
    doAuth,
    doRequestMagicLink,
    Authenticated,
    setAuthenticated
  } = useContext(GeneralContext);
  const [LastName, setLastName] = useState("");
  const [CID, setCID] = useState("");
  const [FormErrors, setFormErrors] = useState({ LastName: false, CID: false });
  const [RestorePassFormErrors, setRestorePassFormErrors] = useState({
    CID: false
  });
  const [Loading, setLoading] = useState(false);
  const [restorePassword, setRestorePassword] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (Authenticated) {
      history.push("/app");
    }
    return () => {
      setLastName("");
      setCID("");
      setFormErrors({ LastName: false, CID: false });
      setRestorePassFormErrors({ CID: false });
    };
  }, []);

  const loginFormValid = () => {
    setFormErrors({ LastName: !Boolean(LastName), CID: !Boolean(CID) });
    if (!FormErrors.LastName && !FormErrors.CID) return true;
    return false;
  };
  const restorePassFormValid = () => {
    setRestorePassFormErrors({ CID: !Boolean(CID) });
    if (!RestorePassFormErrors.CID) return true;
    return false;
  };

  const decodeStr = str => {
    if (typeof str !== "string") return str;
    let parser = new DOMParser();
    let dom = parser.parseFromString(
      "<!doctype html><body>" + str,
      "text/html"
    );
    return dom.body.textContent;
  };

  const loginKeyPress = event => {
    if (event.key === "Enter") {
      authorize();
    }
  };

  const restorePassKeyPress = event => {
    if (event.key === "Enter") {
      sendMagicLink();
    }
  };

  const authorize = () => {
    if (loginFormValid()) {
      setLoading(true);
      doAuth(
        {
          reserva: CID.trim(),
          apellido: LastName.trim(),
          companyid: PosData.CompanyPos
        },
        true
      )
        .then(res => {
          document.body.scrollTop = 0; // For Safari
          document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
          setAuthenticated(true);
          setLoading(false);
          history.push("/app/checkin/" + CID.trim());
        })
        .catch(err => {
          toast.error(<FormattedMessage id="error.loginData" />);
          setAuthenticated(false);
          setLoading(false);
          console.error("AUTH ERROR", err);
          history.push("/app/error");
        });
    }
  };

  const sendMagicLink = () => {
    if (restorePassFormValid()) {
      setLoading(true);
      doRequestMagicLink({
        cid: CID.trim(),
        company_id: PosData.CompanyPos
      })
        .then(() => {
          document.body.scrollTop = 0; // For Safari
          document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
          toast.success(<FormattedMessage id="login.successSendMagicLink" />);
          setLoading(false);
        })
        .catch(err => {
          toast.error(<FormattedMessage id="error.loginData" />);
          console.error("AUTH ERROR", err);
          setLoading(false);
        });
    }
  };

  return (
    <Grid
      container
      direction={"column"}
      alignItems="stretch"
      className={"LoginPage"}
      style={{ width: "100%" }}
    >
      <Grid item style={{ height: "30vh", maxHeight: "20em" }}>
        <img
          src={
            ProductData.foto_tapa ? (
              picsUrl(
                ProductData.foto_tapa,
                Math.min(600, window.outerWidth),
                Math.min(600, window.outerWidth) * 0.75
              )
            ) : (
              <Skeleton height="300" variant="wave" />
            )
          }
          className="pxwm-loginhero"
        ></img>
      </Grid>
      <Grid item xs>
        <Box p={2} pt={4} pb={4}>
          <Typography align="center" variant="h5" component="h1" gutterBottom>
            <strong>
              <FormattedMessage
                values={{
                  productTitle: decodeStr(
                    ProductData.additional_data
                      ? ProductData.additional_data.title
                      : ""
                  )
                }}
                id="login.title"
              />
            </strong>
          </Typography>
          <Typography align="center" variant="subtitle1" color="textSecondary">
            <FormattedMessage id="login.intro" />
          </Typography>
        </Box>
      </Grid>
      <Divider variant="middle" />
      <form>
        <Grid item xs>
          {restorePassword ? (
            <Box p={3}>
              <Box pb={3}>
                <Typography align="center" variant="body1">
                  <FormattedMessage id="login.restorePassword" />
                </Typography>
              </Box>
              <Container maxWidth="sm">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      label={<FormattedMessage id="login.cid" />}
                      value={CID}
                      error={RestorePassFormErrors.CID}
                      onKeyPress={restorePassKeyPress}
                      onBlur={restorePassFormValid}
                      onInput={e => {
                        setCID(e.target.value);
                      }}
                      variant="outlined"
                      name="password"
                    />
                  </Grid>
                  <Grid item xs={12} className="ButtonWrapper">
                    <Box>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        fullWidth
                        disabled={Loading}
                        endIcon={<ChevronRightIcon />}
                        onClick={() => sendMagicLink()}
                      >
                        <FormattedMessage id="login.sendMagicLink" />
                      </Button>
                      {Loading && (
                        <CircularProgress
                          size={24}
                          className="ButtonProgress"
                        />
                      )}
                    </Box>
                  </Grid>
                </Grid>
                <Grid item xs={12} className="ButtonWrapper">
                  <Box mt={2}>
                    <Button
                      variant="text"
                      color="primary"
                      size="large"
                      fullWidth
                      disabled={Loading}
                      startIcon={<ChevronLeftIcon />}
                      onClick={() => setRestorePassword(!restorePassword)}
                    >
                      <FormattedMessage id="login.toggleRestorePasswordBack" />
                    </Button>
                  </Box>
                </Grid>
              </Container>
            </Box>
          ) : (
            <Box p={3}>
              <Box pb={3}>
                <Typography align="center" variant="body1">
                  <FormattedMessage id="login.formIntro" />
                </Typography>
              </Box>
              <Container maxWidth="sm">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      autoFocus
                      required
                      label={<FormattedMessage id="login.lastname" />}
                      value={LastName}
                      error={FormErrors.LastName}
                      onKeyPress={loginKeyPress}
                      inputProps={{ autoComplete: "family-name" }}
                      onInput={e => {
                        setLastName(e.target.value);
                      }}
                      variant="outlined"
                      name="username"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      label={<FormattedMessage id="login.cid" />}
                      value={CID}
                      error={FormErrors.CID}
                      onKeyPress={loginKeyPress}
                      onBlur={loginFormValid}
                      onInput={e => {
                        setCID(e.target.value);
                      }}
                      variant="outlined"
                      name="password"
                    />
                  </Grid>
                  <Grid item xs={12} className="ButtonWrapper">
                    <Box>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        fullWidth
                        disabled={Loading}
                        endIcon={<ChevronRightIcon />}
                        onClick={() => authorize()}
                      >
                        <FormattedMessage id="login.submit" />
                      </Button>
                      {Loading && (
                        <CircularProgress
                          size={24}
                          className="ButtonProgress"
                        />
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} className="ButtonWrapper">
                    <Box>
                      <Button
                        variant="text"
                        color="primary"
                        size="large"
                        fullWidth
                        disabled={Loading}
                        endIcon={<ChevronRightIcon />}
                        onClick={() => setRestorePassword(!restorePassword)}
                      >
                        <FormattedMessage id="login.toggleRestorePassword" />
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Box>
          )}
        </Grid>
      </form>
    </Grid>
  );
};

export default LoginPage;
