import ImgixClient from "imgix-core-js";

let client = new ImgixClient({
  domain: "picsv3.pxsol.com",
  secureURLToken: "TsenSTVE9hdD9C6f",
});

export const picsUrl = (url, width, height) => {
  if (!url) return false;
  let newUrl = url.replace("http://", "");
  newUrl = url.replace("https://", "");
  newUrl = newUrl.replace("files.pxsol.com", "");
  newUrl = newUrl.replace("drive.pxsol.com", "");
  newUrl = newUrl.replace("picsv3.pxsol.com", "");
  newUrl = newUrl.replace("files-public-web.s3-us-west-2.amazonaws.com", "");
  newUrl = newUrl.replace("files-public-web.s3.us-west-2.amazonaws.com", "");
  newUrl = newUrl.replace("files-private.s3.us-west-2.amazonaws.com", "");
  newUrl = newUrl.split("?")[0];
  let params = {};
  if (height && Number(height)) params.h = Number(height);
  if (width && Number(width)) params.w = Number(width);
  let picUrl = client.buildURL(newUrl, params);
  return picUrl;
};

export const isEmail = (email) => {
  return /\S+@\S+\.\S+/.test(email);
};
