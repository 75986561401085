import React, { useContext, useState, useEffect, memo } from "react";
import { useParams } from "react-router-dom";
import { Grid, Box, CircularProgress } from "@material-ui/core";
import WizardStep from "../../components/WizardStep/WizardStep";
import PageTitle from "../../components/PageTitle/PageTitle";
import { GeneralContext } from "../../contexts/GeneralContext";
import { toast } from "react-toastify";
import PaxForm from "../../components/PaxForm/PaxForm";

const PaxEdit = props => {
  let { id, cid } = useParams();
  const [Loading, setLoading] = useState(false);
  const { PaxsData, setPaxsData, fetchData, CIDData, ReviewMode, LngLink } =
    useContext(GeneralContext);

  useEffect(() => {
    if (!PaxsData) {
      setLoading(true);
      fetchData("PaxsData", CIDData.IDInbox)
        .then(res => {
          console.log("PAXDATA", res);
          setPaxsData(res);
        })
        .catch(err => {
          console.error(err);
          toast.error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      console.log("PaxData already loaded on a previous screen");
      setLoading(false);
    }
  }, []);

  if (Loading)
    return (
      <Grid container justify="center" alignItems="center">
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  return (
    <Grid container>
      <Grid item xs={12} style={{ width: "100%" }}>
        <WizardStep activeStep={0} />
      </Grid>
      <Grid item xs={12}>
        <PageTitle
          previousPage={
            ReviewMode
              ? `${
                  LngLink + process.env.REACT_APP_ROOT_PATH_LINK
                }/checkin/${cid}/verify`
              : LngLink +
                process.env.REACT_APP_ROOT_PATH_LINK +
                `/checkin/${cid}/paxs`
          }
          title="paxs.title"
          description="paxs.description"
        />
      </Grid>
      <Grid item xs={12}>
        <Box pl={2} pr={2} pb={4}>
          <PaxForm paxId={id} cid={cid} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default memo(PaxEdit);
