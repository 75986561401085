import React, { useContext, useEffect } from "react";
import {
  Grid,
  Box,
  Card,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import WizardStep from "../../components/WizardStep/WizardStep";
import PageTitle from "../../components/PageTitle/PageTitle";
import { GeneralContext } from "../../contexts/GeneralContext";
import { useParams, Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

const DocumentUpload = (props) => {
  const { DocTypes, setPreviousPage, LngLink } = useContext(GeneralContext);
  let { id, cid } = useParams();

  useEffect(() => {
    setPreviousPage(null);
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} style={{ width: "100%" }}>
        <WizardStep activeStep={0} />
      </Grid>
      <Grid item xs={12}>
        <PageTitle
          title="docUpload.title"
          description="docUpload.description"
        />
      </Grid>
      <Grid item xs={12}>
        <Box p={2} pt={0}>
          {DocTypes && DocTypes.length ? (
            DocTypes.map((docItem) => (
              <Box mb={1} key={docItem.nameId}>
                <Link
                  to={`${LngLink + process.env.REACT_APP_ROOT_PATH_LINK}/checkin/${cid}/paxs/${id}/docs/${docItem.docType}/recos`}
                  style={{ textDecoration: "none" }}
                >
                  <Card variant="outlined" style={{ listStyle: "none" }}>
                    <ListItem button>
                      <ListItemAvatar>
                        <Avatar
                          imgProps={{ style: { objectFit: "contain" } }}
                          style={{ overflow: "visible" }}
                          alt={docItem.name}
                          src={docItem.iconAsset}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <FormattedMessage
                            id={docItem.nameId}
                            defaultMessage={docItem.defaultName}
                          />
                        }
                        secondary={
                          <FormattedMessage
                            id={docItem.descriptionId}
                            defaultMessage={docItem.defaultDescription}
                          />
                        }
                      />
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          color="primary"
                          aria-label="Seleccionar"
                        >
                          <ChevronRightIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </Card>
                </Link>
              </Box>
            ))
          ) : (
            <Box display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default DocumentUpload;
