import React from "react";
import "./WebBookingManager.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import LoginPage from "./screens/LoginPage/LoginPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GeneralContextProvider, {
  GeneralContext
} from "./contexts/GeneralContext";
import MenuPage from "./screens/MenuPage/MenuPage";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import CheckInMain from "./screens/CheckInMain/CheckInMain";
import {
  Container,
  CircularProgress,
  Box,
  Typography,
  Button,
  createMuiTheme,
  ThemeProvider
} from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import WarningIcon from "@material-ui/icons/Warning";
import AppHeader from "./components/AppHeader/AppHeader";
import Paxs from "./screens/Wizard/CheckIn/Paxs";
import PaxEdit from "./screens/PaxEdit/PaxEdit";
import Verify from "./screens/Wizard/CheckIn/Verify";
import DocumentUpload from "./screens/DocumentUpload/DocumentUpload";
import DocumentStages from "./screens/DocumentUpload/DocumentStages";
import Signature from "./screens/Signature/Signature";
import Finish from "./screens/Finish/Finish";
import CancelBooking from "./screens/CancelBooking/CancelBooking";
import ErrorRute from "./components/ErrorRoute/ErrorRute";

function WebBookingManager(props) {
  const path = process.env.REACT_APP_ROOT_PATH;
  return (
    <Router>
      <GeneralContextProvider language={props.language}>
        <GeneralContext.Consumer>
          {context => (
            <ThemeProvider
              theme={createMuiTheme({
                palette: {
                  primary: {
                    main: context.PosData
                      ? context.PosData.ThemeColor
                        ? context.PosData.ThemeColor
                        : "#000000"
                      : "#000000"
                  }
                }
              })}
            >
              {!context.Loading ? (
                <React.Fragment>
                  {context.Authenticated && <AppHeader />}
                  <Container
                    disableGutters={true}
                    maxWidth="sm"
                    className="WebBookingManager"
                  >
                    <Box>
                      <Switch>
                        <PrivateRoute path="/" exact component={null} />
                        <PrivateRoute
                          exact
                          path={path + "/"}
                          component={MenuPage}
                        />

                        <PrivateRoute
                          path={path + "/checkin/:cid"}
                          exact
                          component={CheckInMain}
                        />
                        <PrivateRoute
                          path={path + "/cancel/:cid"}
                          exact
                          component={CancelBooking}
                        />
                        <PrivateRoute
                          path={path + "/checkin/:cid/paxs"}
                          exact
                          component={Paxs}
                        />
                        <PrivateRoute
                          path={path + "/checkin/:cid/paxs/:id"}
                          exact
                          component={PaxEdit}
                        />
                        <PrivateRoute
                          path={path + "/checkin/:cid/verify"}
                          exact
                          component={Verify}
                        />
                        <PrivateRoute
                          path={path + "/checkin/:cid/paxs/:id/docs"}
                          exact
                          component={DocumentUpload}
                        />
                        <PrivateRoute
                          path={
                            path + "/checkin/:cid/paxs/:id/docs/:type/:stage"
                          }
                          exact
                          component={DocumentStages}
                        />
                        <PrivateRoute
                          path={path + "/checkin/:cid/signature"}
                          exact
                          component={Signature}
                        />
                        <PrivateRoute
                          path={path + "/checkin/:cid/finish"}
                          exact
                          component={Finish}
                        />
                        <Route
                          path={path + "/login"}
                          exact
                          component={LoginPage}
                        />
                        <Route
                          path={path + "/error"}
                          exact
                          component={ErrorRute}
                        />
                      </Switch>
                    </Box>
                  </Container>
                </React.Fragment>
              ) : (
                <Box
                  p={3}
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                  justifyContent="center"
                  style={{
                    width: "100%",
                    height: "100vh",
                    boxSizing: "border-box"
                  }}
                >
                  <CircularProgress size={75} color="inherit" />
                  <Box pt={3}>
                    <Typography variant="subtitle1" color="textSecondary">
                      <FormattedMessage id="main.loading" />
                    </Typography>
                  </Box>
                </Box>
              )}
              <ToastContainer
                position="top-left"
                autoClose={4000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
            </ThemeProvider>
          )}
        </GeneralContext.Consumer>
      </GeneralContextProvider>
    </Router>
  );
}

export default WebBookingManager;
