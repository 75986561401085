import React, { useEffect, useContext, useState } from "react";
import { Typography, Box } from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import { GeneralContext } from "../../contexts/GeneralContext";
import { Helmet } from "react-helmet";

const PageTitle = (props) => {
  const intl = useIntl();
  const { ProductData, setPreviousPage } = useContext(GeneralContext);
  const [Meta, setMeta] = useState({ title: "", description: "" });
  if (!props.title) props.title = "notitle";
  if (!props.description) props.description = "nodescription";

  useEffect(() => {
    if (props.previousPage) {
      setPreviousPage(props.previousPage);
    } else {
      setPreviousPage(null);
    }
  }, []);

  useEffect(() => {
    let windowTitle = intl.formatMessage({
      id: props.title,
    });
    let windowDescription = intl.formatMessage({
      id: props.description,
    });
    if (ProductData && ProductData.additional_data.title)
      windowTitle += ` - ${ProductData.additional_data.title}`;
    setMeta({ title: windowTitle, description: windowDescription });
  }, []);
  return (
    <Box
      p={3}
      pt={props.pt ? props.pt : 3}
      pb={props.pb ? props.pb : 3}
      pr={props.pr ? props.pr : 3}
      pl={props.pl ? props.pl : 3}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>{Meta.title}</title>
        <meta name="description" content={Meta.description} />
      </Helmet>

      <Typography variant="h6" align="center">
        <strong>
          <FormattedMessage id={props.title} />
        </strong>
      </Typography>
      <Typography variant="subtitle2" align="center">
        <FormattedMessage id={props.description} />
      </Typography>
    </Box>
  );
};

export default PageTitle;
