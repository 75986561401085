import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Box, Button, Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import WarningIcon from "@material-ui/icons/Warning";
import { GeneralContext } from "../../contexts/GeneralContext";

function ErrorRoute() {
  const history = useHistory();
  const { setAuthenticated } = useContext(GeneralContext);
  const handleBackToHome = () => {
    setAuthenticated(false);
    localStorage.removeItem("token");
    history.push("/app");
  };

  return (
    <Box
      p={3}
      display="flex"
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
      style={{
        width: "100%",
        height: "100vh",
        boxSizing: "border-box"
      }}
    >
      <WarningIcon fontSize="large" color="secondary" />
      <Box p={3}>
        <Typography align="center" variant="subtitle1" color="textSecondary">
          <FormattedMessage id="main.generalError" />
        </Typography>
      </Box>
      <Button onClick={() => window.location.reload()}>
        <FormattedMessage id="main.retry" />
      </Button>
      <Button onClick={handleBackToHome}>
        <FormattedMessage id="main.backToHome" />
      </Button>
    </Box>
  );
}

export default ErrorRoute;
