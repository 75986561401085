export const reportErrorToNewRelic = (enpoint, error) => {
  const token = localStorage.getItem("token");
  if (typeof window.newrelic !== "undefined") {
    try {
      window.newrelic.noticeError(JSON.stringify({ enpoint, error, token }));
      console.log("Error enviado a New Relic", {
        enpoint,
        json: JSON.stringify({ error, token })
      });
    } catch (error) {
      console.error("Error enviando error a New Relic", error);
    }
  } else {
    console.warn("New Relic is not defined");
  }
};
