import DNI from './../assets/icons/docTypes/DNI.svg';
import Passport from './../assets/icons/docTypes/Passport.svg';
import Foreign_ID from './../assets/icons/docTypes/Foreign_ID.svg';
import DNI_Front_Ex from './../assets/img/docTypes/DNI_Front_Example@2x.jpg';
import Passport_ex from './../assets/img/docTypes/Passport_ex.jpg';
import DNI_Back_Ex from './../assets/img/docTypes/DNI_Back_Example@2x.jpg';

const docTypes = [{
    id : 1,
    docType: 'dni_argentino',
    nameId : 'docType.dni',
    defaultName : 'Documento de Identidad',
    descriptionId : 'docType.dniDescription',
    defaultDescription: 'Documento de Identidad tarjeta u otro válido y vigente',
    iconAsset: DNI,
    hasFront: true,
    hasBack: true,
    frontExample: DNI_Front_Ex,
    frontTextId: "photo.dni_front_desc",
    backExample: DNI_Back_Ex,
    backTextId: "photo.dni_back_desc",
},{
    id : 2,
    docType: 'passport',
    nameId : 'docType.passport',
    defaultName : 'Pasaporte',
    descriptionId : 'docType.passportDescription',
    defaultDescription: 'Vigente y en buen estado',
    iconAsset: Passport,
    hasFront: true,
    hasBack: false,
    frontExample: Passport_ex,
    frontTextId: "photo.passport_front_desc",
    backExample: null,
    backTextId: null,
}
]

export default docTypes;