import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import WebBookingManager from "./WebBookingManager";
import * as serviceWorker from "./serviceWorker";
import { IntlProvider } from "react-intl";
import messages_es from "./translations/es.json";
import messages_en from "./translations/en.json";
import messages_pt from "./translations/pt.json";

const messages = {
  es: messages_es,
  en: messages_en,
  pt: messages_pt
};

const language =
  navigator.language?.split("-")[0] || navigator.userLanguage?.split("-")[0];

// let urlLngEn = window.location.href.indexOf("/en/") !== -1 ? "en" : false;
// let urlLngPt = window.location.href.indexOf("/pt/") !== -1 ? "pt" : false;
// const language = urlLngEn || urlLngPt ? urlLngEn || urlLngPt : "es";

ReactDOM.render(
  <React.StrictMode>
    <IntlProvider
      locale={language}
      messages={messages?.[language] || messages?.en}
    >
      <WebBookingManager language={language} />
    </IntlProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your WebBookingManager to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register({
//   scope: language === 'es' ? '/app/' : `/${language}/app/`
//  });
