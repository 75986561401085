import React, { useContext, useState, useEffect } from "react";
import {
  Grid,
  CircularProgress,
  Box,
  Button,
  IconButton,
  Typography,
  ListItem,
  List,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Divider,
  Card,
  CardContent,
  FormControlLabel,
  Checkbox,
  Link,
  CardActionArea,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogActions,
  DialogContent
} from "@material-ui/core";
import WizardStep from "../../../components/WizardStep/WizardStep";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { Redirect, useParams } from "react-router-dom";
import { GeneralContext } from "../../../contexts/GeneralContext";
import { toast } from "react-toastify";
import { FormattedMessage } from "react-intl";
import demoInputs from "../../../constants/demoInputs";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CreateIcon from "@material-ui/icons/Create";
import moment from "moment";
import SanitizedHTML from "react-sanitized-html";
import HttpService from "../../../services/HttpService";
import { reportErrorToNewRelic } from "../../../helpers/newRelic";

const Verify = () => {
  const {
    CIDData,
    setCIDData,
    PaxsData,
    setPaxsData,
    ProductData,
    fetchData,
    setReviewMode,
    confirmBooking,
    CIDSignature,
    BookingReady,
    setBookingReady,
    LngLink
  } = useContext(GeneralContext);
  const [Loading, setLoading] = useState(true);
  const [CompletedPaxs, setCompletedPaxs] = useState(0);
  const [GoEdit, setGoEdit] = useState(false);
  const { cid } = useParams();
  const [ShowDocs, setShowDocs] = useState({});
  const [TermsAccepted, setTermsAccepted] = useState(false);
  const [Submitting, setSubmitting] = useState(false);
  const [RedirectFinish, setRedirectFinish] = useState(false);
  const [EditSigRedirect, setEditSigRedirect] = useState(false);
  const [OpenDialog, setOpenDialog] = useState(false);
  const [inputFields, setInputFields] = useState(demoInputs);

  useEffect(() => {
    setPaxsData(null);
    fetchData("PaxsData", CIDData.IDInbox)
      .then(res => {
        console.log("PAXDATA", res);
        let completedPaxs = 0;
        res.list.forEach(pax => {
          if (pax.staterate === "complete") completedPaxs++;
        });
        setCompletedPaxs(completedPaxs);
        setPaxsData(res);

        HttpService.get(
          `${process.env.REACT_APP_API2_EP}${process.env.REACT_APP_CUSTOM_FIELDS_LIST}?product_id=${ProductData.id}`
        )
          .then(res => {
            console.log("Custom Fields List Response = ", res);
            if (res.data.fields) {
              let newInputFields = [...inputFields].map(field => {
                let customField = res.data.fields.find(
                  customField => customField.field_name === field.field_name
                );
                if (customField) {
                  field = { ...field, ...customField };
                  field.hidden = field.hidden == "true" ? true : false;
                  field.required = field.required == "true" ? true : false;
                  field.review_only =
                    field.review_only == "true" ? true : false;
                  field.validation_type =
                    field.validation_type == "false"
                      ? false
                      : field.validation_type;
                }
                return field;
              });
              setInputFields(newInputFields);
              setLoading(false);
            }
          })
          .catch(err => {
            console.error(err);
            reportErrorToNewRelic("FIELDS_LIST", err);
          });
      })
      .catch(err => {
        console.error(err);
        toast.error(err);
        setLoading(false);
      });
  }, []);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  async function handleSubmit() {
    if (!TermsAccepted) return;
    try {
      setSubmitting(true);
      let confirmResult = await confirmBooking();
      toast.success(<FormattedMessage id="verify.success" />);
      console.log("exito submit", confirmResult);
      setBookingReady([...BookingReady, cid]);
      setSubmitting(false);
      setRedirectFinish(true);
      setCIDData({ ...CIDData, finish: true });
    } catch (error) {
      toast.error(error.message);
      console.error(error);
    }
  }

  const editSig = () => {
    setEditSigRedirect(true);
  };

  const editPax = paxId => {
    if (!paxId) return false;
    setPaxsData(null);
    setReviewMode(true);
    setGoEdit(paxId);
  };
  if (GoEdit)
    return (
      <Redirect
        push
        to={`${
          LngLink + process.env.REACT_APP_ROOT_PATH_LINK
        }/checkin/wizard/paxs/${GoEdit}`}
      />
    );
  return (
    <Grid container>
      <Grid item xs={12} style={{ width: "100%" }}>
        <WizardStep activeStep={2} />
      </Grid>
      <Grid item xs={12}>
        <PageTitle title="verify.title" description="verify.description" />
      </Grid>

      <Grid item xs={12}>
        <Box
          p={2}
          display="flex"
          justifyContent="center"
          style={{ width: "100%", boxSizing: "border-box" }}
        >
          {Loading ? (
            <CircularProgress />
          ) : (
            <Grid container spacing={2}>
              {PaxsData && PaxsData.list.length > 0
                ? PaxsData.list.map(pax => (
                    <Grid item xs={12} key={pax.paxid}>
                      <List
                        dense
                        subheader={
                          <ListSubheader
                            style={{ top: "70px", backgroundColor: "white" }}
                          >
                            <Grid container>
                              <Grid item xs>
                                <strong>
                                  {pax.reservationholder ? (
                                    <FormattedMessage id="verify.holder" />
                                  ) : (
                                    <FormattedMessage id="verify.companion" />
                                  )}
                                </strong>
                              </Grid>
                              <Grid item xs align="right">
                                <Button
                                  color="primary"
                                  onClick={() => editPax(pax.paxid)}
                                >
                                  <FormattedMessage id="verify.editPax" />{" "}
                                  <ChevronRightIcon />
                                </Button>
                              </Grid>
                            </Grid>
                          </ListSubheader>
                        }
                      >
                        <Divider />
                        {pax && pax.documents && pax.documents.length ? (
                          <ListItem key={`Docs_${pax.paxid[0]}`} dense>
                            <ListItemText
                              primary={
                                <Typography
                                  variant="caption"
                                  color="textSecondary"
                                >
                                  {<FormattedMessage id="verify.docs" />}
                                </Typography>
                              }
                              secondary={
                                <Typography noWrap>
                                  <FormattedMessage id="verify.docsReady" />
                                  <Button
                                    size="small"
                                    variant="text"
                                    color="primary"
                                    onClick={() =>
                                      setShowDocs({
                                        ...ShowDocs,
                                        [pax.paxid]: !ShowDocs[pax.paxid]
                                      })
                                    }
                                  >
                                    {ShowDocs[pax.paxid] ? (
                                      <FormattedMessage id="verify.hideDocs" />
                                    ) : (
                                      <FormattedMessage id="verify.seeDocs" />
                                    )}
                                  </Button>
                                </Typography>
                              }
                            />
                            <ListItemSecondaryAction>
                              <IconButton
                                onClick={() => editPax(pax.paxid)}
                                size="small"
                              >
                                <CreateIcon fontSize="small" />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        ) : (
                          ""
                        )}
                        {ShowDocs[pax.paxid] &&
                        pax &&
                        pax.documents &&
                        pax.documents.length ? (
                          <ListItem>
                            <Card variant="outlined">
                              <CardContent>
                                <Grid container spacing={2}>
                                  {pax && pax.documents && pax.documents.length
                                    ? pax.documents.map(doc => (
                                        <Grid item xs={6}>
                                          <img
                                            src={doc.url}
                                            alt={doc.label}
                                            style={{
                                              width: "100%",
                                              height: "100%",
                                              objectFit: "contain"
                                            }}
                                          />
                                        </Grid>
                                      ))
                                    : ""}
                                </Grid>
                              </CardContent>
                            </Card>
                          </ListItem>
                        ) : (
                          ""
                        )}

                        {Object.entries(pax).map(paxData =>
                          inputFields.find(
                            input => input.field_name === paxData[0]
                          ) &&
                          ((inputFields.find(
                            input => input.field_name === paxData[0]
                          ).pax_type === "holder" &&
                            pax.reservationholder) ||
                            inputFields.find(
                              input => input.field_name === paxData[0]
                            ).pax_type === "all") &&
                          !inputFields.find(
                            input => input.field_name === paxData[0]
                          ).hidden ? (
                            <ListItem
                              key={paxData[0]}
                              dense
                              button
                              onClick={() => editPax(pax.paxid)}
                            >
                              <ListItemText
                                primary={
                                  <Typography
                                    variant="caption"
                                    color="textSecondary"
                                  >
                                    {
                                      <FormattedMessage
                                        id={
                                          inputFields.find(
                                            input =>
                                              input.field_name === paxData[0]
                                          ).name_id
                                        }
                                        defaultMessage={
                                          inputFields.find(
                                            input =>
                                              input.field_name === paxData[0]
                                          ).default_name
                                        }
                                      />
                                    }
                                  </Typography>
                                }
                                secondary={
                                  <Typography noWrap>
                                    {paxData[1] ? (
                                      inputFields.find(
                                        input => input.field_name === paxData[0]
                                      ).type === "date" ? (
                                        moment(paxData[1]).format("L")
                                      ) : inputFields.find(
                                          input =>
                                            input.field_name === paxData[0]
                                        ).type === "time" ? (
                                        moment(paxData[1]).format("LT")
                                      ) : (
                                        paxData[1]
                                      )
                                    ) : (
                                      <Typography color="textSecondary">
                                        <FormattedMessage id="verify.nodata" />
                                      </Typography>
                                    )}
                                  </Typography>
                                }
                              />
                              <ListItemSecondaryAction>
                                <IconButton
                                  onClick={() => editPax(pax.paxid)}
                                  size="small"
                                >
                                  <CreateIcon fontSize="small" />
                                </IconButton>
                              </ListItemSecondaryAction>
                            </ListItem>
                          ) : (
                            ""
                          )
                        )}
                        {pax.reservationholder ? (
                          <ListItem
                            button
                            key={`Signature_${pax.paxid[0]}`}
                            dense
                            onClick={() => editSig(cid)}
                          >
                            <ListItemText
                              primary={
                                <Typography
                                  variant="caption"
                                  color="textSecondary"
                                >
                                  {<FormattedMessage id="verify.sig" />}
                                </Typography>
                              }
                              secondary={
                                <Typography noWrap>
                                  <FormattedMessage id="verify.sigReady" />
                                </Typography>
                              }
                            />
                            <ListItemSecondaryAction>
                              <IconButton
                                onClick={() => editSig(cid)}
                                size="small"
                              >
                                <CreateIcon fontSize="small" />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        ) : (
                          ""
                        )}
                        {pax.reservationholder &&
                        CIDSignature &&
                        CIDSignature[cid] ? (
                          <ListItem key={`Signature2_${pax.paxid[0]}`} dense>
                            <Card variant="outlined">
                              <CardActionArea onClick={() => editSig(cid)}>
                                <CardContent>
                                  <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                      <img
                                        src={CIDSignature[cid]}
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          objectFit: "contain"
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                </CardContent>
                              </CardActionArea>
                            </Card>
                          </ListItem>
                        ) : (
                          ""
                        )}
                      </List>
                    </Grid>
                  ))
                : ""}
            </Grid>
          )}
        </Box>
      </Grid>

      {!Loading && (
        <Grid item xs={12}>
          <Box p={2} pr={4} pl={4} display="flex" justifyContent="center">
            <FormControlLabel
              control={
                <Checkbox
                  checked={TermsAccepted}
                  onChange={() => setTermsAccepted(!TermsAccepted)}
                  name="acceptTerms"
                  color="primary"
                />
              }
              label={
                <FormattedMessage
                  id={
                    ProductData.additional_data.checkin_policy_text
                      ? "verify.acceptTerms"
                      : "verify.acceptTermsNoData"
                  }
                  values={{
                    TermsAndConditionsLink: (
                      <Link onClick={handleClickOpen}>
                        <FormattedMessage id="verify.termsAndConditions" />
                      </Link>
                    )
                  }}
                />
              }
            />
          </Box>
          <Dialog
            open={OpenDialog}
            keepMounted
            fullWidth
            maxWidth="sm"
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle id="alert-dialog-slide-title">
              {<FormattedMessage id="verify.termsAndCondTitle" />}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                {
                  <SanitizedHTML
                    allowedAttributes={{ a: ["href"] }}
                    allowedTags={["a", "p", "strong", "b", "i", "br"]}
                    html={ProductData.additional_data.checkin_policy_text}
                  />
                }
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                <FormattedMessage id="verify.close" />
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      )}
      {!Loading && (
        <Grid item xs={12} className="ButtonWrapper">
          <Box p={2}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              disabled={!TermsAccepted || Submitting}
              endIcon={<ChevronRightIcon />}
              onClick={handleSubmit}
            >
              <FormattedMessage id="verify.submit" />
            </Button>
            {Submitting && (
              <CircularProgress size={24} className="ButtonProgress" />
            )}
          </Box>
        </Grid>
      )}
      <Grid item xs={12}>
        <Box p={3}></Box>
      </Grid>
      {!Loading && PaxsData && CompletedPaxs < PaxsData.list.length ? (
        <Redirect
          to={`${
            LngLink + process.env.REACT_APP_ROOT_PATH_LINK
          }/checkin/${cid}/paxs`}
        />
      ) : (
        ""
      )}
      {!Loading && RedirectFinish ? (
        <Redirect
          push
          to={`${
            LngLink + process.env.REACT_APP_ROOT_PATH_LINK
          }/checkin/${cid}/finish`}
        />
      ) : (
        ""
      )}
      {!Loading && EditSigRedirect ? (
        <Redirect
          push
          to={`${
            LngLink + process.env.REACT_APP_ROOT_PATH_LINK
          }/checkin/${cid}/signature`}
        />
      ) : (
        ""
      )}
    </Grid>
  );
};

export default Verify;
