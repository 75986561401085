import React, { useContext, useState, useEffect } from "react";
import {
  TextField,
  withStyles,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  FormHelperText,
  Typography,
  Box
} from "@material-ui/core";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  DatePicker,
  DateTimePicker
} from "@material-ui/pickers";
import "moment/locale/es";
import "moment/locale/pt";

import { useIntl, FormattedMessage } from "react-intl";
import { GeneralContext } from "../../contexts/GeneralContext";
import Autocomplete from "@material-ui/lab/Autocomplete";
import "./PaxInput.css";
import MuiPhoneNumber from "material-ui-phone-number";
import "react-phone-input-2/lib/material.css";
import es from "react-phone-input-2/lang/es.json";
import pt from "react-phone-input-2/lang/pt.json";
import { isEmail } from "../../helpers/helpers";

// const urlLngEn = window.location.href.indexOf("/en/") !== -1 ? "en" : false;
// const urlLngPt = window.location.href.indexOf("/pt/") !== -1 ? "pt" : false;
// // const urlLngEs = window.location.href.indexOf('/es/') !== -1 ? true : false;
// // const navigatorLng = navigator.language.split(/[-_]/)[0];

const navigatorLanguage =
  navigator.language?.split("-")?.[0] ||
  navigator.userLanguage?.split("-")?.[0];
const language =
  navigatorLanguage === "en" ? false : navigatorLanguage === "pt" ? pt : es;
// const locale = urlLngEn || urlLngPt ? urlLngEn || urlLngPt : "es";
const locale =
  navigatorLanguage === "en" ? "en" : navigatorLanguage === "pt" ? "pt" : "es";
console.log("locale", locale);

moment.locale(locale);

const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "rgba(0,0,0,.05)"
      },
      "& input": {
        backgroundColor: "#F5F5F5"
      },
      "& textarea": {
        backgroundColor: "#F5F5F5"
      }
    },
    "& .MuiOutlinedInput-multiline": {
      backgroundColor: "#F5F5F5"
    }
  }
})(TextField);

const CssAutocomplete = withStyles({
  root: {
    "& .MuiTextField-root": {
      borderColor: "rgba(0,0,0,.05)",
      backgroundColor: "#F5F5F5"
    },
    "& .MuiOutlinedInput-inputMultiline": {
      borderColor: "rgba(0,0,0,.05)",
      backgroundColor: "#F5F5F5"
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "rgba(0,0,0,.05)"
      },
      "& input": {
        backgroundColor: "#F5F5F5"
      }
    },
    // eslint-disable-next-line no-dupe-keys
    "& .MuiOutlinedInput-inputMultiline": {
      "& fieldset": {
        borderColor: "rgba(0,0,0,.05)"
      },
      "& input": {
        backgroundColor: "#F5F5F5"
      }
    }
  }
})(Autocomplete);

function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
        .toUpperCase()
        .replace(/./g, char =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
}

const PaxInputMemo = props => {
  const [InputError, setInputError] = useState(false);
  const {
    LocalPaxData,
    setLocalPaxData,
    ReviewMode,
    Validating,
    CountriesData,
    ProductData,
    SIRECities
  } = useContext(GeneralContext);
  const [InputValue, setInputValue] = useState(
    props.value ? props.value : undefined
  );
  const [AutocompleteValue, setAutocompleteValue] = useState(
    CountriesData.List.find(country => country.Value === props.value)
  );
  const [autocompleteSireValue, setAutocompleteSireValue] = useState(
    SIRECities?.list?.find(city => city.name === props.value)
  );

  const [HelperText, setHelperText] = useState("");
  const intl = useIntl();

  useEffect(() => {
    if (Validating > 0) isInputValid();
  }, [Validating]);

  if (!props.type || !props.paxId) return null;
  //console.log("re render input", props.fieldName);

  const changePaxData = (field, value) => {
    if (!field) return;
    //if (typeof LocalPaxData[props.paxId][field] === "undefined") return;
    let paxData = { ...LocalPaxData };
    if (paxData[props.paxId][field] !== value) {
      paxData[props.paxId][field] = value;
      setLocalPaxData(paxData);
      console.log("setlocalpaxdata ");
    }
  };

  const handleOnChange = e => {
    if (props.validationType === "alphanumeric")
      e.target.value = e.target.value.replace(/[^\w\s]/gi, "");
    setInputValue(e.target.value);
  };

  const isInputValid = () => {
    let valid = true;
    let helperText = false;
    if (!props.hidden) {
      if (props.required && !InputValue) {
        console.log("input invalid", props.fieldName, "required");
        helperText = "paxInput.required";
        valid = false;
      }
      if (
        props.validationType === "email" &&
        InputValue &&
        !isEmail(InputValue)
      ) {
        console.log("input invalid", props.fieldName, "invalidEmail");
        helperText = "paxInput.invalidEmail";
        valid = false;
      }
      if (props.type === "date" || props.type === "time") {
        console.log("input invalid", props.fieldName, "invalidDate");
        if (!moment(InputValue).isValid()) valid = false;
        if (InputValue === "0000-00-00") {
          valid = false;
          helperText = "paxInput.required";
        }
      }
    }

    setInputError(!valid);
    setHelperText(helperText);
    return valid;
  };

  const handleOnBlur = e => {
    console.log("blur", e);
    isInputValid();
    if (props.type !== "date" && props.type !== "time") {
      changePaxData(props.fieldName, InputValue);
    }
    if (props.type === "date") {
      if (!isInputValid()) return false;
      changePaxData(props.fieldName, moment(InputValue).format("YYYY-MM-DD"));
    }
    if (props.type === "time") {
      if (!isInputValid()) return false;
      changePaxData(props.fieldName, moment(InputValue).format());
    }
  };
  if (
    props.reviewOnly &&
    !ReviewMode &&
    !LocalPaxData[props.paxId].documents.length
  )
    return null;
  if (props.hidden)
    return <input type="hidden" name={props.fieldName} value={InputValue} />;
  switch (props.type) {
    case "text":
    case "textarea":
      return (
        <CssTextField
          required={props.required ? true : false}
          id={props.fieldName}
          multiline={props.type === "textarea"}
          name={props.fieldName}
          fullWidth
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          value={InputValue}
          error={InputError}
          helperText={HelperText ? <FormattedMessage id={HelperText} /> : ""}
          placeholder={intl.formatMessage({
            id: props.placeholderId,
            defaultMessage: props.defaultPlaceholder
          })}
          inputProps={{
            autoComplete: props.autocomplete
          }}
          label={
            <FormattedMessage
              id={props.nameId}
              defaultMessage={props.defaultName}
            />
          }
          variant="outlined"
        />
      );

    case "time":
      return (
        <MuiPickersUtilsProvider utils={MomentUtils} locale={locale}>
          <DateTimePicker
            clearable
            fullWidth
            ampm={false}
            inputVariant="outlined"
            label={
              <FormattedMessage
                id={props.nameId}
                defaultMessage={props.defaultName}
              />
            }
            className="GrayInput"
            required={props.required}
            onBlur={handleOnBlur}
            format={"lll"}
            error={InputError}
            value={InputValue ? moment(InputValue) : moment(props.startDate)}
            onChange={setInputValue}
            invalidDateMessage={
              <FormattedMessage
                id={"paxInput.timeError"}
                defaultMessage={"Fecha inválida"}
              />
            }
            cancelLabel={
              <FormattedMessage
                id={"paxInput.cancelTimeLabel"}
                defaultMessage={"Cancelar"}
              />
            }
            clearLabel={
              <FormattedMessage
                id={"paxInput.clearTimeLabel"}
                defaultMessage={"Borrar"}
              />
            }
            okLabel={
              <FormattedMessage
                id={"paxInput.okTimeLabel"}
                defaultMessage={"Ok"}
              />
            }
          />
          <Box p={1}>
            <Typography variant="caption" align="center">
              <FormattedMessage
                id="paxs.checkindatetime"
                values={{
                  checkintext: ProductData.additional_data.checkin_text
                }}
              />
            </Typography>
          </Box>
        </MuiPickersUtilsProvider>
      );
    case "date":
      return (
        <MuiPickersUtilsProvider utils={MomentUtils} locale={locale}>
          <DatePicker
            disableFuture
            inputVariant="outlined"
            openTo="year"
            format="L"
            fullWidth
            error={InputError}
            helperText={HelperText ? <FormattedMessage id={HelperText} /> : ""}
            className="GrayInput"
            required={props.required}
            onBlur={handleOnBlur}
            label={
              <FormattedMessage
                id={props.nameId}
                defaultMessage={props.defaultName}
              />
            }
            views={["year", "month", "date"]}
            value={InputValue !== "0000-00-00" ? moment(InputValue) : null}
            onChange={setInputValue}
          />
        </MuiPickersUtilsProvider>
      );
    case "country":
      return (
        <CssAutocomplete
          id={props.fieldName}
          fullWidth
          error={InputError}
          helperText={HelperText ? <FormattedMessage id={HelperText} /> : ""}
          options={CountriesData.List}
          autoHighlight
          value={AutocompleteValue}
          onChange={(event, newValue) => {
            setAutocompleteValue(newValue);
          }}
          onBlur={() => {
            if (AutocompleteValue)
              changePaxData(props.fieldName, AutocompleteValue.Value);
          }}
          inputValue={InputValue}
          onInputChange={(event, newInputValue) => {
            console.log("niv", newInputValue);
            setInputValue(newInputValue);
          }}
          getOptionSelected={(option, value) => option.Code === value.Code}
          getOptionLabel={option => option.Name}
          renderOption={option => (
            <React.Fragment>
              <span style={{ marginRight: "10px" }}>
                {countryToFlag(option.Code)}
              </span>
              {option.Name} ({option.Code})
            </React.Fragment>
          )}
          renderInput={params => (
            <CssTextField
              {...params}
              error={InputError}
              helperText={
                HelperText ? <FormattedMessage id={HelperText} /> : ""
              }
              label={
                <FormattedMessage
                  id={props.nameId}
                  defaultMessage={props.defaultName}
                />
              }
              variant="outlined"
              required={props.required}
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password" // disable autocomplete and autofill
              }}
            />
          )}
        />
      );
    case "destination":
      return (
        <CssAutocomplete
          id={props.fieldName}
          fullWidth
          error={InputError}
          helperText={HelperText ? <FormattedMessage id={HelperText} /> : ""}
          options={SIRECities?.list ?? []}
          autoHighlight
          value={autocompleteSireValue}
          onChange={(event, newValue) => {
            setAutocompleteSireValue(newValue);
          }}
          onBlur={() => {
            if (autocompleteSireValue)
              changePaxData(props.fieldName, autocompleteSireValue.name);
          }}
          inputValue={InputValue}
          onInputChange={(event, newInputValue) => {
            console.log("niv", newInputValue);
            setInputValue(newInputValue);
          }}
          // getOptionSelected={(option, value) => option.name === value.name}
          getOptionLabel={option => option.name}
          renderInput={params => (
            <CssTextField
              {...params}
              error={InputError}
              helperText={
                HelperText ? <FormattedMessage id={HelperText} /> : ""
              }
              label={
                <FormattedMessage
                  id={props.nameId}
                  defaultMessage={props.defaultName}
                />
              }
              variant="outlined"
              required={props.required}
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password" // disable autocomplete and autofill
              }}
            />
          )}
        />
      );
    case "phone":
      return (
        <MuiPhoneNumber
          id={props.fieldName}
          error={InputError}
          enableLongNumbers
          helperText={HelperText ? <FormattedMessage id={HelperText} /> : ""}
          defaultCountry={"ar"}
          variant="outlined"
          preferredCountries={["ar", "br", "cl", "uy", "us"]}
          onChange={setInputValue}
          disableAreaCodes={true}
          label={
            <FormattedMessage
              id={props.nameId}
              defaultMessage={props.defaultName}
            />
          }
          onBlur={handleOnBlur}
          value={InputValue}
          localization={language}
          autoFormat={false}
          className="GrayInput"
          fullWidth
          inputProps={{
            name: props.fieldName,
            required: props.required,
            autoComplete: "tel"
          }}
        />
      );
    case "select":
      let selectValues = props.selectValues;
      if (typeof selectValues === "function") {
        selectValues = selectValues(LocalPaxData[props.paxId]);
      }

      return (
        <FormControl variant="outlined" className="GrayInput" fullWidth>
          <InputLabel id={`Label_${props.fieldName}`}>
            <FormattedMessage
              id={props.nameId}
              defaultMessage={props.defaultName}
            />
          </InputLabel>
          <Select
            error={InputError}
            label_id={`Label_${props.fieldName}`}
            id={props.fieldName}
            value={InputValue}
            onBlur={handleOnBlur}
            onChange={handleOnChange}
            required={props.required}
            label={
              <FormattedMessage
                id={props.nameId}
                defaultMessage={props.defaultName}
              />
            }
          >
            <MenuItem value="" disabled>
              <em>
                <FormattedMessage id="paxInput.chooseOne" />
              </em>
            </MenuItem>
            {selectValues &&
            typeof selectValues === "object" &&
            selectValues.length
              ? selectValues.map(val => {
                  if (!val.label && val.name) val.label = val.name;
                  if (!val.label_id && val.value) val.label_id = val.value;
                  return (
                    <MenuItem value={val.value} key={val.label_id}>
                      <FormattedMessage
                        id={val.label_id}
                        defaultMessage={val.label}
                      />
                    </MenuItem>
                  );
                })
              : null}
          </Select>
          {HelperText && (
            <FormHelperText>
              <FormattedMessage id={HelperText} />
            </FormHelperText>
          )}
        </FormControl>
      );
    default:
      return null;
  }
};

const areEqual = (prevProps, nextProps) => {
  if (JSON.stringify(prevProps) === JSON.stringify(nextProps)) {
    return true;
  } else {
    return false;
  }
};

const PaxInput = React.memo(PaxInputMemo, areEqual);

export default PaxInput;
