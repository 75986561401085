import React, { useState, useContext } from "react";
import {
  Grid,
  Divider,
  Box,
  Typography,
  Button,
  CircularProgress,
} from "@material-ui/core";
import PageTitle from "../PageTitle/PageTitle";
import recos from "../../constants/recos";
import { FormattedMessage } from "react-intl";
import { Redirect } from "react-router-dom";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { GeneralContext } from "../../contexts/GeneralContext";

const PhotoRecos = (props) => {
  const {LngLink} = useContext(GeneralContext);
  const [RedirectOn, setRedirectOn] = useState(false);
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <PageTitle
          pt={1}
          title="docStages.howTo"
          description="docStages.howToDescription"
        />
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Box pt={2} pb={2}>
          <Grid container spacing={1}>
            {recos && recos.length
              ? recos.map((reco) => (
                  <Grid item xs key={reco.nameId}>
                    <Grid container spacing={1} justify="center">
                      <Grid item xs={12}>
                        <Box align="center" justifyContent="center">
                          <img
                            style={{ width: "100%" }}
                            src={reco.image}
                            alt="reco1"
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          variant="caption"
                          component="div"
                          align="center"
                          style={{
                            lineHeight: 1,
                            letterSpacing: "normal",
                          }}
                        >
                          <FormattedMessage id={reco.nameId} />
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ))
              : ""}
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12} className="ButtonWrapper">
        <Button
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          endIcon={<ChevronRightIcon />}
          onClick={() => setRedirectOn(true)}
        >
          <FormattedMessage id="photo.continue" />
        </Button>
        {RedirectOn && (
          <CircularProgress size={24} className="ButtonProgress" />
        )}
      </Grid>
      {RedirectOn ? <Redirect push to={`${LngLink + process.env.REACT_APP_ROOT_PATH_LINK}/checkin/${props.cid}/paxs/${props.paxId}/docs/${props.docType}/upload`} /> : ""}
    </Grid>
  );
};

export default PhotoRecos;
