import React, { useContext, useEffect, useMemo, useState } from "react";
import "./PaxForm.css";

import {
  Card,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Button,
  Avatar,
  Box,
  CircularProgress,
  CardContent,
  Typography,
  Grid,
  CardActionArea,
  Hidden,
  Tooltip,
  IconButton,
  List,
  ListItemIcon,
  Drawer
} from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import { GeneralContext } from "../../contexts/GeneralContext";
import { toast } from "react-toastify";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import PaxInput from "../PaxInput/PaxInput";
import demoInputs from "../../constants/demoInputs";
import { isEmail } from "../../helpers/helpers";
import { Redirect, Link } from "react-router-dom";
import DNI from "../../assets/icons/docTypes/DNI.svg";
import CheckIcon from "@material-ui/icons/Check";
import ShareIcon from "@material-ui/icons/Share";
import {
  WhatsappShareButton,
  FacebookMessengerShareButton,
  EmailShareButton
} from "react-share";

import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import FacebookIcon from "@material-ui/icons/Facebook";
import EmailIcon from "@material-ui/icons/Email";
import LinkIcon from "@material-ui/icons/Link";
import HttpService from "../../services/HttpService";
import { reportErrorToNewRelic } from "../../helpers/newRelic";

const PaxForm = props => {
  const intl = useIntl();

  const {
    PaxsData,
    LocalPaxData,
    CIDData,
    UserData,
    setLocalPaxData,
    ReviewMode,
    submitPaxData,
    setPaxsData,
    setValidating,
    ProductData,
    Validating,
    LngLink
  } = useContext(GeneralContext);

  const [Loading, setLoading] = useState(true);
  const [Submitting, setSubmitting] = useState(false);
  const [SuccessRedirect, setSuccessRedirect] = useState(false);
  const [ErrorDocs, setErrorDocs] = useState(false);
  const [AnchorEl, setAnchorEl] = useState();
  const [inputFields, setInputFields] = useState(demoInputs);
  const [requiredDocument, setRequiredDocument] = useState(false);
  const [hiddenDocument, setHiddenDocument] = useState(false);

  const localPaxData = useMemo(
    () => LocalPaxData[props.paxId],
    [LocalPaxData, props.paxId]
  );

  useEffect(() => {
    setValidating(0);
    HttpService.get(
      `${process.env.REACT_APP_API2_EP}${process.env.REACT_APP_CUSTOM_FIELDS_LIST}?product_id=${ProductData.id}`
    )
      .then(res => {
        console.log("Custom Fields List Response = ", res);
        if (res.data.fields) {
          console.log("Custom Fields", res?.data?.fields?.length);
          let newInputFields = [...inputFields].map(field => {
            let customField = res.data.fields.find(
              customField => customField.field_name === field.field_name
            );
            if (customField) {
              field = { ...field, ...customField };
              field.hidden = field.hidden === "true" ? true : false;
              field.required = field.required === "true" ? true : false;
              field.review_only = field.review_only === "true" ? true : false;
              field.validation_type =
                field.validation_type === "false"
                  ? false
                  : field.validation_type;
              field.order = parseInt(customField.order) || 999;
            }
            return field;
          });
          newInputFields.sort((a, b) => (a.order || 999) - (b.order || 999));
          console.log("Setting new input fields", newInputFields);
          setInputFields(newInputFields);
          setLoading(false);
        }
      })
      .catch(err => {
        reportErrorToNewRelic("FIELDS_LIST", err);
      });
  }, []);

  useEffect(() => {
    const documentField = inputFields.find(field => field.field_name === "documentupload");
    if (!documentField) return;
    
    const documentApplies = inputDoesApply(localPaxData, documentField);
    
    if (documentField.required && !documentField.hidden && documentApplies) {
      setRequiredDocument(true);
    } else {
      setRequiredDocument(false);
    }

    if (documentField.hidden || !documentApplies) {
      setHiddenDocument(true);
    } else {
      setHiddenDocument(false);
    }
  }, [inputFields, localPaxData]);

  const validateForm = () => {
    let valid = !inputFields.some(input => {
      if (input.field_name === "documentupload") return false;
      
      if (!inputDoesApply(localPaxData, input)) return false;
      
      if (input.type === "date" || input.type === "time") {
        return input.required && !input.hidden && !localPaxData[input.field_name];
      }
      
      return (input.required &&
        !input.hidden &&
        !localPaxData[input.field_name] &&
        localPaxData[input.field_name] !== 0) ||
        (input.validation_type === "email" &&
          localPaxData[input.field_name] &&
          !isEmail(localPaxData[input.field_name]));
    });
    
    if (requiredDocument && !hiddenDocument) {
      let docsValid = localPaxData?.documents && localPaxData?.documents.length > 0;
      valid = docsValid && valid;
      setErrorDocs(!docsValid);
    }
    
    setValidating(Validating + 1);
    return valid;
  };

  const inputDoesApply = (paxData, inputData) => {
    if (!paxData) return false;
    if (!inputData || !inputData.pax_type) return false;
    return (
      inputData.pax_type === "all" ||
      (paxData.reservationholder && inputData.pax_type === "holder") ||
      (!paxData.reservationholder && inputData.pax_type === "companion")
    );
  };

  useEffect(() => {
    if (!props.paxId) return;
    if (!PaxsData || !PaxsData.list) return;
    let currentLocalPax = localPaxData;
    if (!currentLocalPax) {
      let currentPax = PaxsData.list.find(
        pax => pax.paxid == Number(props.paxId)
      );
      if (!currentPax) {
        toast.error(<FormattedMessage id="paxForm.noData" />);
        return;
      }
      setLocalPaxData({ ...LocalPaxData, [props.paxId]: currentPax });
    }
  }, [PaxsData]);

  const handleSubmit = () => {
    let valid = validateForm();
    if (!valid) {
      toast.error(<FormattedMessage id="paxForm.formInvalid" />);
      return false;
    }
    setSubmitting(true);
    submitPaxData(props.paxId)
      .then(res => {
        console.log("success update pax data", res);
        toast.success(<FormattedMessage id="paxForm.updateSuccess" />);
        setPaxsData(null);

        setSuccessRedirect(true);
      })
      .catch(err => {
        toast.error(err);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };
  const toggleDrawer = event => {
    setAnchorEl(AnchorEl ? null : event.currentTarget);
  };

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(
        `${window.location.protocol}//${window.location.host}${
          window.location.pathname
        }?cid=${UserData.CID}&lastname=${encodeURI(
          UserData.LastName.trim()
        )}&c=${UserData.CompanyID}`
      )
      .then(res => {
        toast.success(<FormattedMessage id="share.copied" />);
      });
  };

  if (!props.paxId) return <FormattedMessage id="paxForm.noPaxId" />;
  if (Loading)
    return (
      <Box
        p={3}
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{ width: "100%", boxSizing: "border-box" }}
      >
        <CircularProgress />
      </Box>
    );
  return (
    <Card variant="outlined" style={{ listStyle: "none" }}>
      <ListItem>
        <ListItemAvatar>
          <Avatar />
        </ListItemAvatar>
        <ListItemText
          primary={
            localPaxData?.lastname && localPaxData?.firstname ? (
              `${localPaxData?.firstname} ${localPaxData?.lastname}`
            ) : localPaxData?.reservationholder ? (
              <FormattedMessage id="paxs.reservationHolder" />
            ) : (
              <FormattedMessage id="paxs.companion" />
            )
          }
          secondary={
            localPaxData?.staterate === "complete" ? (
              <span>
                <FormattedMessage id="paxs.complete" />
              </span>
            ) : (
              <span>
                <FormattedMessage id="paxs.pending" />
              </span>
            )
          }
        />
        <ListItemSecondaryAction>
          <Hidden smUp>
            <Tooltip title={<FormattedMessage id="main.share" />}>
              <IconButton
                edge="end"
                color="primary"
                aria-label="Compartir"
                onClick={toggleDrawer}
              >
                <ShareIcon />
              </IconButton>
            </Tooltip>
          </Hidden>
          <Hidden xsDown>
            <Button color="primary" onClick={toggleDrawer}>
              <FormattedMessage id="paxForm.goShare" /> <ChevronRightIcon />
            </Button>
          </Hidden>
          <Drawer
            anchor={"bottom"}
            open={Boolean(AnchorEl)}
            onClose={toggleDrawer}
          >
            <div
              role="presentation"
              onClick={toggleDrawer}
              onKeyDown={toggleDrawer}
            >
              <List>
                <WhatsappShareButton
                  windowHeight={window.outerHeight * 0.8}
                  windowWidth={window.outerWidth * 0.8}
                  style={{ width: "100%" }}
                  url={`${window.location.protocol}//${window.location.host}${
                    window.location.pathname
                  }?cid=${UserData.CID}&lastname=${encodeURI(
                    UserData.LastName.trim()
                  )}&c=${UserData.CompanyID}`}
                  title={intl.formatMessage(
                    { id: "share.whatsApp.title" },
                    { hotelName: ProductData.additional_data.title }
                  )}
                >
                  <ListItem button>
                    <ListItemIcon>
                      <WhatsAppIcon />
                    </ListItemIcon>
                    <ListItemText primary={"WhatsApp"} />
                  </ListItem>
                </WhatsappShareButton>
              </List>
              <List>
                <FacebookMessengerShareButton
                  style={{ width: "100%" }}
                  appId="308044173695892"
                  url={`${window.location.protocol}//${window.location.host}${
                    window.location.pathname
                  }?cid=${UserData.CID}&lastname=${encodeURI(
                    UserData.LastName.trim()
                  )}&c=${UserData.CompanyID}`}
                  quote={intl.formatMessage(
                    { id: "share.whatsApp.title" },
                    { hotelName: ProductData.additional_data.title }
                  )}
                >
                  <ListItem button>
                    <ListItemIcon>
                      <FacebookIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Facebook"} />
                  </ListItem>
                </FacebookMessengerShareButton>
              </List>
              <List>
                <EmailShareButton
                  style={{ width: "100%" }}
                  url={`${window.location.protocol}//${window.location.host}${
                    window.location.pathname
                  }?cid=${UserData.CID}&lastname=${encodeURI(
                    UserData.LastName.trim()
                  )}&c=${UserData.CompanyID}`}
                  subject={intl.formatMessage(
                    { id: "share.whatsApp.title" },
                    { hotelName: ProductData.additional_data.title }
                  )}
                >
                  <ListItem button>
                    <ListItemIcon>
                      <EmailIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Email"} />
                  </ListItem>
                </EmailShareButton>
              </List>
              <List>
                <ListItem button onClick={copyToClipboard}>
                  <ListItemIcon>
                    <LinkIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={<FormattedMessage id="share.copy.name" />}
                  />
                </ListItem>
              </List>
            </div>
          </Drawer>
        </ListItemSecondaryAction>
      </ListItem>
      <CardContent>
        <Grid container>
          <Grid item xs={12} hidden={hiddenDocument}>
            <Box pb={2}>
              <Link
                style={{ textDecoration: "none" }}
                to={`${
                  LngLink + process.env.REACT_APP_ROOT_PATH_LINK
                }/checkin/${props.cid}/paxs/${props.paxId}/docs`}
              >
                <Card
                  variant="outlined"
                  style={
                    localPaxData?.documents && localPaxData?.documents.length
                      ? {}
                      : ErrorDocs
                      ? { borderColor: "red" }
                      : { border: "1px dashed #ccc" }
                  }
                >
                  <CardActionArea>
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={8}>
                          <Typography variant="body1" gutterBottom>
                            <strong>
                              <FormattedMessage id="paxForm.documentation" />
                              {requiredDocument ? <span>*</span> : null}
                            </strong>
                            <span style={{ fontSize: "10px", marginLeft: "4px" }}>
                              {!requiredDocument ? <FormattedMessage id="paxForm.optional" /> : null}
                            </span>
                          </Typography>
                          <Box pb={1}>
                            <Typography variant="body2" gutterBottom>
                              <FormattedMessage id="paxForm.docDesc" />
                            </Typography>
                          </Box>
                          <Button
                            color={
                              localPaxData?.documents &&
                              localPaxData?.documents.length
                                ? "inherit"
                                : "primary"
                            }
                            variant="outlined"
                            endIcon={<ChevronRightIcon />}
                          >
                            <FormattedMessage
                              id={
                                localPaxData?.documents &&
                                localPaxData?.documents.length
                                  ? "paxForm.docChange"
                                  : "paxForm.docCta"
                              }
                            />
                          </Button>
                        </Grid>
                        <Grid item xs={4}>
                          <Box
                            pt={2}
                            pb={2}
                            pr={2}
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                            style={{ height: "100%", boxSizing: "border-box" }}
                          >
                            {localPaxData?.documents &&
                            localPaxData?.documents.length ? (
                              <Avatar
                                style={{
                                  border: "1px solid #4DD82433",
                                  backgroundColor: "rgb(237, 247, 237)",
                                  color: "#4DD824"
                                }}
                              >
                                <CheckIcon />
                              </Avatar>
                            ) : (
                              <img src={DNI} alt="Document" width="60" />
                            )}
                          </Box>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Link>
              {ErrorDocs ? (
                <Box pt={2}>
                  <Typography variant="body2" color="error">
                    <FormattedMessage id="paxForm.docError" />
                  </Typography>
                </Box>
              ) : null}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box pb={2}>
              <Typography variant="body1" gutterBottom>
                <strong>
                  <FormattedMessage id={hiddenDocument ? "paxForm.1additionalDetails" : "paxForm.2additionalDetails"} />
                </strong>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            {inputFields && inputFields.length ? (
              inputFields.map(input =>
                inputDoesApply(localPaxData, input) ? (
                  <Box
                    pb={
                      !ReviewMode && !localPaxData?.documents?.length
                        ? input.review_only
                          ? 0
                          : input.hidden
                          ? 0
                          : 2
                        : input.hidden
                        ? 0
                        : 2
                    }
                    style={{ width: "100%", boxSizing: "border-box" }}
                    key={input.field_name}
                  >
                    <PaxInput
                      type={input.type}
                      validationType={input.validation_type}
                      autocomplete={input.autocomplete}
                      pax_type={input.pax_type}
                      reviewOnly={input.review_only}
                      nameId={input.name_id}
                      defaultName={input.default_name}
                      placeholderId={input.placeholder_id}
                      defaultPlaceholder={input.default_placeholder}
                      required={input.required}
                      fieldName={input.field_name}
                      hidden={input.hidden}
                      value={localPaxData[input.field_name]}
                      selectValues={input.select_values}
                      defaultValue={input.default_value}
                      paxId={props.paxId}
                      startDate={CIDData.fehcaingreso}
                      validateFormFn={validateForm}
                    />
                  </Box>
                ) : null
              )
            ) : (
              <CircularProgress />
            )}
          </Grid>
          <Grid item xs={12} className="ButtonWrapper">
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              disabled={Submitting}
              endIcon={<ChevronRightIcon />}
              onClick={handleSubmit}
            >
              <FormattedMessage id="paxForm.submit" />
            </Button>
            {Submitting && (
              <CircularProgress size={24} className="ButtonProgress" />
            )}
          </Grid>
          {SuccessRedirect ? (
            <Redirect
              to={
                ReviewMode
                  ? `${
                      LngLink + process.env.REACT_APP_ROOT_PATH_LINK
                    }/checkin/${props.cid}/verify`
                  : `${
                      LngLink + process.env.REACT_APP_ROOT_PATH_LINK
                    }/checkin/${props.cid}/paxs`
              }
            />
          ) : (
            ""
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PaxForm;
