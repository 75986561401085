import React from "react";
import { Grid } from "@material-ui/core";
import HotelInfo from "../../components/HotelInfo/HotelInfo";
import BookingCard from "../../components/BookingCard/BookingCard";

const CheckInMain = () => {
  return (
    <Grid container direction="column">
      <Grid item style={{ width: "100%" }}>
        <HotelInfo />
      </Grid>
      <Grid item>
        <BookingCard />
      </Grid>
    </Grid>
  );
};

export default CheckInMain;
