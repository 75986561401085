import { reportErrorToNewRelic } from "../helpers/newRelic";
import httpService from "./HttpService";

class AuthService {
  constructor() {
    this.loginBooking = this.loginBooking.bind(this);
  }

  loginBooking(data) {
    const { reserva, apellido, companyid } = data;
    if (!reserva || !apellido || !companyid) {
      console.error(
        "Datos faltantes o incorrectos",
        data,
        reserva,
        apellido,
        companyid
      );
      return Promise.reject("Datos faltantes o incorrectos");
    }
    return httpService
      .post(process.env.REACT_APP_LOGIN_EP, { reserva, apellido, companyid })
      .then(res => {
        if (res.data.token) {
          localStorage.setItem("token", res.data.token);
          localStorage.setItem(
            "UserData",
            JSON.stringify({
              CID: data.reserva,
              LastName: data.apellido,
              CompanyID: data.companyid
            })
          );
          console.log("Login realizado con exito");
        }
        return res.data;
      })
      .catch(err => {
        console.error("Error en el login", err);
        reportErrorToNewRelic("LOGIN_EP", err);
        return Promise.reject(err);
      });
  }
}

const authServiceInstance = new AuthService();
export default authServiceInstance;
