const CountriesList = [
    { Code: 'AD', Name: 'Andorra', Value: 'Andorra', phone: '376' },
    { Code: 'AE', Name: 'United Arab Emirates', Value: 'United Arab Emirates', phone: '971' },
    { Code: 'AF', Name: 'Afghanistan', Value: 'Afghanistan', phone: '93' },
    { Code: 'AG', Name: 'Antigua and Barbuda', Value: 'Antigua and Barbuda', phone: '1-268' },
    { Code: 'AI', Name: 'Anguilla', Value: 'Anguilla', phone: '1-264' },
    { Code: 'AL', Name: 'Albania', Value: 'Albania', phone: '355' },
    { Code: 'AM', Name: 'Armenia', Value: 'Armenia', phone: '374' },
    { Code: 'AO', Name: 'Angola', Value: 'Angola', phone: '244' },
    { Code: 'AQ', Name: 'Antarctica', Value: 'Antarctica', phone: '672' },
    { Code: 'AR', Name: 'Argentina', Value: 'Argentina', phone: '54' },
    { Code: 'AS', Name: 'American Samoa', Value: 'American Samoa', phone: '1-684' },
    { Code: 'AT', Name: 'Austria', Value: 'Austria', phone: '43' },
    { Code: 'AU', Name: 'Australia', Value: 'Australia', phone: '61', suggested: true },
    { Code: 'AW', Name: 'Aruba', Value: 'Aruba', phone: '297' },
    { Code: 'AX', Name: 'Alland Islands', Value: 'Alland Islands', phone: '358' },
    { Code: 'AZ', Name: 'Azerbaijan', Value: 'Azerbaijan', phone: '994' },
    { Code: 'BA', Name: 'Bosnia and Herzegovina', Value: 'Bosnia and Herzegovina', phone: '387' },
    { Code: 'BB', Name: 'Barbados', Value: 'Barbados', phone: '1-246' },
    { Code: 'BD', Name: 'Bangladesh', Value: 'Bangladesh', phone: '880' },
    { Code: 'BE', Name: 'Belgium', Value: 'Belgium', phone: '32' },
    { Code: 'BF', Name: 'Burkina Faso', Value: 'Burkina Faso', phone: '226' },
    { Code: 'BG', Name: 'Bulgaria', Value: 'Bulgaria', phone: '359' },
    { Code: 'BH', Name: 'Bahrain', Value: 'Bahrain', phone: '973' },
    { Code: 'BI', Name: 'Burundi', Value: 'Burundi', phone: '257' },
    { Code: 'BJ', Name: 'Benin', Value: 'Benin', phone: '229' },
    { Code: 'BL', Name: 'Saint Barthelemy', Value: 'Saint Barthelemy', phone: '590' },
    { Code: 'BM', Name: 'Bermuda', Value: 'Bermuda', phone: '1-441' },
    { Code: 'BN', Name: 'Brunei Darussalam', Value: 'Brunei Darussalam', phone: '673' },
    { Code: 'BO', Name: 'Bolivia', Value: 'Bolivia', phone: '591' },
    { Code: 'BR', Name: 'Brazil', Value: 'Brazil', phone: '55' },
    { Code: 'BS', Name: 'Bahamas', Value: 'Bahamas', phone: '1-242' },
    { Code: 'BT', Name: 'Bhutan', Value: 'Bhutan', phone: '975' },
    { Code: 'BV', Name: 'Bouvet Island', Value: 'Bouvet Island', phone: '47' },
    { Code: 'BW', Name: 'Botswana', Value: 'Botswana', phone: '267' },
    { Code: 'BY', Name: 'Belarus', Value: 'Belarus', phone: '375' },
    { Code: 'BZ', Name: 'Belize', Value: 'Belize', phone: '501' },
    { Code: 'CA', Name: 'Canada', Value: 'Canada', phone: '1', suggested: true },
    { Code: 'CC', Name: 'Cocos (Keeling) Islands', Value: 'Cocos (Keeling) Islands', phone: '61' },
    { Code: 'CD', Name: 'Congo, Democratic Republic of the', Value: 'Congo, Democratic Republic of the', phone: '243' },
    { Code: 'CF', Name: 'Central African Republic', Value: 'Central African Republic', phone: '236' },
    { Code: 'CG', Name: 'Congo, Republic of the', Value: 'Congo, Republic of the', phone: '242' },
    { Code: 'CH', Name: 'Switzerland', Value: 'Switzerland', phone: '41' },
    { Code: 'CI', Name: "Cote d'Ivoire", Value: "Cote d'Ivoire", phone: '225' },
    { Code: 'CK', Name: 'Cook Islands', Value: 'Cook Islands', phone: '682' },
    { Code: 'CL', Name: 'Chile', Value: 'Chile', phone: '56' },
    { Code: 'CM', Name: 'Cameroon', Value: 'Cameroon', phone: '237' },
    { Code: 'CN', Name: 'China', Value: 'China', phone: '86' },
    { Code: 'CO', Name: 'Colombia', Value: 'Colombia', phone: '57' },
    { Code: 'CR', Name: 'Costa Rica', Value: 'Costa Rica', phone: '506' },
    { Code: 'CU', Name: 'Cuba', Value: 'Cuba', phone: '53' },
    { Code: 'CV', Name: 'Cape Verde', Value: 'Cape Verde', phone: '238' },
    { Code: 'CW', Name: 'Curacao', Value: 'Curacao', phone: '599' },
    { Code: 'CX', Name: 'Christmas Island', Value: 'Christmas Island', phone: '61' },
    { Code: 'CY', Name: 'Cyprus', Value: 'Cyprus', phone: '357' },
    { Code: 'CZ', Name: 'Czech Republic', Value: 'Czech Republic', phone: '420' },
    { Code: 'DE', Name: 'Germany', Value: 'Germany', phone: '49', suggested: true },
    { Code: 'DJ', Name: 'Djibouti', Value: 'Djibouti', phone: '253' },
    { Code: 'DK', Name: 'Denmark', Value: 'Denmark', phone: '45' },
    { Code: 'DM', Name: 'Dominica', Value: 'Dominica', phone: '1-767' },
    { Code: 'DO', Name: 'Dominican Republic', Value: 'Dominican Republic', phone: '1-809' },
    { Code: 'DZ', Name: 'Algeria', Value: 'Algeria', phone: '213' },
    { Code: 'EC', Name: 'Ecuador', Value: 'Ecuador', phone: '593' },
    { Code: 'EE', Name: 'Estonia', Value: 'Estonia', phone: '372' },
    { Code: 'EG', Name: 'Egypt', Value: 'Egypt', phone: '20' },
    { Code: 'EH', Name: 'Western Sahara', Value: 'Western Sahara', phone: '212' },
    { Code: 'ER', Name: 'Eritrea', Value: 'Eritrea', phone: '291' },
    { Code: 'ES', Name: 'Spain', Value: 'Spain', phone: '34' },
    { Code: 'ET', Name: 'Ethiopia', Value: 'Ethiopia', phone: '251' },
    { Code: 'FI', Name: 'Finland', Value: 'Finland', phone: '358' },
    { Code: 'FJ', Name: 'Fiji', Value: 'Fiji', phone: '679' },
    { Code: 'FK', Name: 'Falkland Islands (Malvinas)', Value: 'Falkland Islands (Malvinas)', phone: '500' },
    { Code: 'FM', Name: 'Micronesia, Federated States of', Value: 'Micronesia, Federated States of', phone: '691' },
    { Code: 'FO', Name: 'Faroe Islands', Value: 'Faroe Islands', phone: '298' },
    { Code: 'FR', Name: 'France', Value: 'France', phone: '33', suggested: true },
    { Code: 'GA', Name: 'Gabon', Value: 'Gabon', phone: '241' },
    { Code: 'GB', Name: 'United Kingdom', Value: 'United Kingdom', phone: '44' },
    { Code: 'GD', Name: 'Grenada', Value: 'Grenada', phone: '1-473' },
    { Code: 'GE', Name: 'Georgia', Value: 'Georgia', phone: '995' },
    { Code: 'GF', Name: 'French Guiana', Value: 'French Guiana', phone: '594' },
    { Code: 'GG', Name: 'Guernsey', Value: 'Guernsey', phone: '44' },
    { Code: 'GH', Name: 'Ghana', Value: 'Ghana', phone: '233' },
    { Code: 'GI', Name: 'Gibraltar', Value: 'Gibraltar', phone: '350' },
    { Code: 'GL', Name: 'Greenland', Value: 'Greenland', phone: '299' },
    { Code: 'GM', Name: 'Gambia', Value: 'Gambia', phone: '220' },
    { Code: 'GN', Name: 'Guinea', Value: 'Guinea', phone: '224' },
    { Code: 'GP', Name: 'Guadeloupe', Value: 'Guadeloupe', phone: '590' },
    { Code: 'GQ', Name: 'Equatorial Guinea', Value: 'Equatorial Guinea', phone: '240' },
    { Code: 'GR', Name: 'Greece', Value: 'Greece', phone: '30' },
    { Code: 'GS', Name: 'South Georgia and the South Sandwich Islands', Value: 'South Georgia and the South Sandwich Islands', phone: '500' },
    { Code: 'GT', Name: 'Guatemala', Value: 'Guatemala', phone: '502' },
    { Code: 'GU', Name: 'Guam', Value: 'Guam', phone: '1-671' },
    { Code: 'GW', Name: 'Guinea-Bissau', Value: 'Guinea-Bissau', phone: '245' },
    { Code: 'GY', Name: 'Guyana', Value: 'Guyana', phone: '592' },
    { Code: 'HK', Name: 'Hong Kong', Value: 'Hong Kong', phone: '852' },
    { Code: 'HM', Name: 'Heard Island and McDonald Islands', Value: 'Heard Island and McDonald Islands', phone: '672' },
    { Code: 'HN', Name: 'Honduras', Value: 'Honduras', phone: '504' },
    { Code: 'HR', Name: 'Croatia', Value: 'Croatia', phone: '385' },
    { Code: 'HT', Name: 'Haiti', Value: 'Haiti', phone: '509' },
    { Code: 'HU', Name: 'Hungary', Value: 'Hungary', phone: '36' },
    { Code: 'ID', Name: 'Indonesia', Value: 'Indonesia', phone: '62' },
    { Code: 'IE', Name: 'Ireland', Value: 'Ireland', phone: '353' },
    { Code: 'IL', Name: 'Israel', Value: 'Israel', phone: '972' },
    { Code: 'IM', Name: 'Isle of Man', Value: 'Isle of Man', phone: '44' },
    { Code: 'IN', Name: 'India', Value: 'India', phone: '91' },
    { Code: 'IO', Name: 'British Indian Ocean Territory', Value: 'British Indian Ocean Territory', phone: '246' },
    { Code: 'IQ', Name: 'Iraq', Value: 'Iraq', phone: '964' },
    { Code: 'IR', Name: 'Iran, Islamic Republic of', Value: 'Iran, Islamic Republic of', phone: '98' },
    { Code: 'IS', Name: 'Iceland', Value: 'Iceland', phone: '354' },
    { Code: 'IT', Name: 'Italy', Value: 'Italy', phone: '39' },
    { Code: 'JE', Name: 'Jersey', Value: 'Jersey', phone: '44' },
    { Code: 'JM', Name: 'Jamaica', Value: 'Jamaica', phone: '1-876' },
    { Code: 'JO', Name: 'Jordan', Value: 'Jordan', phone: '962' },
    { Code: 'JP', Name: 'Japan', Value: 'Japan', phone: '81', suggested: true },
    { Code: 'KE', Name: 'Kenya', Value: 'Kenya', phone: '254' },
    { Code: 'KG', Name: 'Kyrgyzstan', Value: 'Kyrgyzstan', phone: '996' },
    { Code: 'KH', Name: 'Cambodia', Value: 'Cambodia', phone: '855' },
    { Code: 'KI', Name: 'Kiribati', Value: 'Kiribati', phone: '686' },
    { Code: 'KM', Name: 'Comoros', Value: 'Comoros', phone: '269' },
    { Code: 'KN', Name: 'Saint Kitts and Nevis', Value: 'Saint Kitts and Nevis', phone: '1-869' },
    { Code: 'KP', Name: "Korea, Democratic People's Republic of", Value: "Korea, Democratic People's Republic of", phone: '850' },
    { Code: 'KR', Name: 'Korea, Republic of', Value: 'Korea, Republic of', phone: '82' },
    { Code: 'KW', Name: 'Kuwait', Value: 'Kuwait', phone: '965' },
    { Code: 'KY', Name: 'Cayman Islands', Value: 'Cayman Islands', phone: '1-345' },
    { Code: 'KZ', Name: 'Kazakhstan', Value: 'Kazakhstan', phone: '7' },
    { Code: 'LA', Name: "Lao People's Democratic Republic", Value: "Lao People's Democratic Republic", phone: '856' },
    { Code: 'LB', Name: 'Lebanon', Value: 'Lebanon', phone: '961' },
    { Code: 'LC', Name: 'Saint Lucia', Value: 'Saint Lucia', phone: '1-758' },
    { Code: 'LI', Name: 'Liechtenstein', Value: 'Liechtenstein', phone: '423' },
    { Code: 'LK', Name: 'Sri Lanka', Value: 'Sri Lanka', phone: '94' },
    { Code: 'LR', Name: 'Liberia', Value: 'Liberia', phone: '231' },
    { Code: 'LS', Name: 'Lesotho', Value: 'Lesotho', phone: '266' },
    { Code: 'LT', Name: 'Lithuania', Value: 'Lithuania', phone: '370' },
    { Code: 'LU', Name: 'Luxembourg', Value: 'Luxembourg', phone: '352' },
    { Code: 'LV', Name: 'Latvia', Value: 'Latvia', phone: '371' },
    { Code: 'LY', Name: 'Libya', Value: 'Libya', phone: '218' },
    { Code: 'MA', Name: 'Morocco', Value: 'Morocco', phone: '212' },
    { Code: 'MC', Name: 'Monaco', Value: 'Monaco', phone: '377' },
    { Code: 'MD', Name: 'Moldova, Republic of', Value: 'Moldova, Republic of', phone: '373' },
    { Code: 'ME', Name: 'Montenegro', Value: 'Montenegro', phone: '382' },
    { Code: 'MF', Name: 'Saint Martin (French part)', Value: 'Saint Martin (French part)', phone: '590' },
    { Code: 'MG', Name: 'Madagascar', Value: 'Madagascar', phone: '261' },
    { Code: 'MH', Name: 'Marshall Islands', Value: 'Marshall Islands', phone: '692' },
    { Code: 'MK', Name: 'Macedonia, the Former Yugoslav Republic of', Value: 'Macedonia, the Former Yugoslav Republic of', phone: '389' },
    { Code: 'ML', Name: 'Mali', Value: 'Mali', phone: '223' },
    { Code: 'MM', Name: 'Myanmar', Value: 'Myanmar', phone: '95' },
    { Code: 'MN', Name: 'Mongolia', Value: 'Mongolia', phone: '976' },
    { Code: 'MO', Name: 'Macao', Value: 'Macao', phone: '853' },
    { Code: 'MP', Name: 'Northern Mariana Islands', Value: 'Northern Mariana Islands', phone: '1-670' },
    { Code: 'MQ', Name: 'Martinique', Value: 'Martinique', phone: '596' },
    { Code: 'MR', Name: 'Mauritania', Value: 'Mauritania', phone: '222' },
    { Code: 'MS', Name: 'Montserrat', Value: 'Montserrat', phone: '1-664' },
    { Code: 'MT', Name: 'Malta', Value: 'Malta', phone: '356' },
    { Code: 'MU', Name: 'Mauritius', Value: 'Mauritius', phone: '230' },
    { Code: 'MV', Name: 'Maldives', Value: 'Maldives', phone: '960' },
    { Code: 'MW', Name: 'Malawi', Value: 'Malawi', phone: '265' },
    { Code: 'MX', Name: 'Mexico', Value: 'Mexico', phone: '52' },
    { Code: 'MY', Name: 'Malaysia', Value: 'Malaysia', phone: '60' },
    { Code: 'MZ', Name: 'Mozambique', Value: 'Mozambique', phone: '258' },
    { Code: 'NA', Name: 'Namibia', Value: 'Namibia', phone: '264' },
    { Code: 'NC', Name: 'New Caledonia', Value: 'New Caledonia', phone: '687' },
    { Code: 'NE', Name: 'Niger', Value: 'Niger', phone: '227' },
    { Code: 'NF', Name: 'Norfolk Island', Value: 'Norfolk Island', phone: '672' },
    { Code: 'NG', Name: 'Nigeria', Value: 'Nigeria', phone: '234' },
    { Code: 'NI', Name: 'Nicaragua', Value: 'Nicaragua', phone: '505' },
    { Code: 'NL', Name: 'Netherlands', Value: 'Netherlands', phone: '31' },
    { Code: 'NO', Name: 'Norway', Value: 'Norway', phone: '47' },
    { Code: 'NP', Name: 'Nepal', Value: 'Nepal', phone: '977' },
    { Code: 'NR', Name: 'Nauru', Value: 'Nauru', phone: '674' },
    { Code: 'NU', Name: 'Niue', Value: 'Niue', phone: '683' },
    { Code: 'NZ', Name: 'New Zealand', Value: 'New Zealand', phone: '64' },
    { Code: 'OM', Name: 'Oman', Value: 'Oman', phone: '968' },
    { Code: 'PA', Name: 'Panama', Value: 'Panama', phone: '507' },
    { Code: 'PE', Name: 'Peru', Value: 'Peru', phone: '51' },
    { Code: 'PF', Name: 'French Polynesia', Value: 'French Polynesia', phone: '689' },
    { Code: 'PG', Name: 'Papua New Guinea', Value: 'Papua New Guinea', phone: '675' },
    { Code: 'PH', Name: 'Philippines', Value: 'Philippines', phone: '63' },
    { Code: 'PK', Name: 'Pakistan', Value: 'Pakistan', phone: '92' },
    { Code: 'PL', Name: 'Poland', Value: 'Poland', phone: '48' },
    { Code: 'PM', Name: 'Saint Pierre and Miquelon', Value: 'Saint Pierre and Miquelon', phone: '508' },
    { Code: 'PN', Name: 'Pitcairn', Value: 'Pitcairn', phone: '870' },
    { Code: 'PR', Name: 'Puerto Rico', Value: 'Puerto Rico', phone: '1' },
    { Code: 'PS', Name: 'Palestine, State of', Value: 'Palestine, State of', phone: '970' },
    { Code: 'PT', Name: 'Portugal', Value: 'Portugal', phone: '351' },
    { Code: 'PW', Name: 'Palau', Value: 'Palau', phone: '680' },
    { Code: 'PY', Name: 'Paraguay', Value: 'Paraguay', phone: '595' },
    { Code: 'QA', Name: 'Qatar', Value: 'Qatar', phone: '974' },
    { Code: 'RE', Name: 'Reunion', Value: 'Reunion', phone: '262' },
    { Code: 'RO', Name: 'Romania', Value: 'Romania', phone: '40' },
    { Code: 'RS', Name: 'Serbia', Value: 'Serbia', phone: '381' },
    { Code: 'RU', Name: 'Russian Federation', Value: 'Russian Federation', phone: '7' },
    { Code: 'RW', Name: 'Rwanda', Value: 'Rwanda', phone: '250' },
    { Code: 'SA', Name: 'Saudi Arabia', Value: 'Saudi Arabia', phone: '966' },
    { Code: 'SB', Name: 'Solomon Islands', Value: 'Solomon Islands', phone: '677' },
    { Code: 'SC', Name: 'Seychelles', Value: 'Seychelles', phone: '248' },
    { Code: 'SD', Name: 'Sudan', Value: 'Sudan', phone: '249' },
    { Code: 'SE', Name: 'Sweden', Value: 'Sweden', phone: '46' },
    { Code: 'SG', Name: 'Singapore', Value: 'Singapore', phone: '65' },
    { Code: 'SH', Name: 'Saint Helena', Value: 'Saint Helena', phone: '290' },
    { Code: 'SI', Name: 'Slovenia', Value: 'Slovenia', phone: '386' },
    { Code: 'SJ', Name: 'Svalbard and Jan Mayen', Value: 'Svalbard and Jan Mayen', phone: '47' },
    { Code: 'SK', Name: 'Slovakia', Value: 'Slovakia', phone: '421' },
    { Code: 'SL', Name: 'Sierra Leone', Value: 'Sierra Leone', phone: '232' },
    { Code: 'SM', Name: 'San Marino', Value: 'San Marino', phone: '378' },
    { Code: 'SN', Name: 'Senegal', Value: 'Senegal', phone: '221' },
    { Code: 'SO', Name: 'Somalia', Value: 'Somalia', phone: '252' },
    { Code: 'SR', Name: 'Suriname', Value: 'Suriname', phone: '597' },
    { Code: 'SS', Name: 'South Sudan', Value: 'South Sudan', phone: '211' },
    { Code: 'ST', Name: 'Sao Tome and Principe', Value: 'Sao Tome and Principe', phone: '239' },
    { Code: 'SV', Name: 'El Salvador', Value: 'El Salvador', phone: '503' },
    { Code: 'SX', Name: 'Sint Maarten (Dutch part)', Value: 'Sint Maarten (Dutch part)', phone: '1-721' },
    { Code: 'SY', Name: 'Syrian Arab Republic', Value: 'Syrian Arab Republic', phone: '963' },
    { Code: 'SZ', Name: 'Swaziland', Value: 'Swaziland', phone: '268' },
    { Code: 'TC', Name: 'Turks and Caicos Islands', Value: 'Turks and Caicos Islands', phone: '1-649' },
    { Code: 'TD', Name: 'Chad', Value: 'Chad', phone: '235' },
    { Code: 'TF', Name: 'French Southern Territories', Value: 'French Southern Territories', phone: '262' },
    { Code: 'TG', Name: 'Togo', Value: 'Togo', phone: '228' },
    { Code: 'TH', Name: 'Thailand', Value: 'Thailand', phone: '66' },
    { Code: 'TJ', Name: 'Tajikistan', Value: 'Tajikistan', phone: '992' },
    { Code: 'TK', Name: 'Tokelau', Value: 'Tokelau', phone: '690' },
    { Code: 'TL', Name: 'Timor-Leste', Value: 'Timor-Leste', phone: '670' },
    { Code: 'TM', Name: 'Turkmenistan', Value: 'Turkmenistan', phone: '993' },
    { Code: 'TN', Name: 'Tunisia', Value: 'Tunisia', phone: '216' },
    { Code: 'TO', Name: 'Tonga', Value: 'Tonga', phone: '676' },
    { Code: 'TR', Name: 'Turkey', Value: 'Turkey', phone: '90' },
    { Code: 'TT', Name: 'Trinidad and Tobago', Value: 'Trinidad and Tobago', phone: '1-868' },
    { Code: 'TV', Name: 'Tuvalu', Value: 'Tuvalu', phone: '688' },
    { Code: 'TW', Name: 'Taiwan, Province of China', Value: 'Taiwan, Province of China', phone: '886' },
    { Code: 'TZ', Name: 'United Republic of Tanzania', Value: 'United Republic of Tanzania', phone: '255' },
    { Code: 'UA', Name: 'Ukraine', Value: 'Ukraine', phone: '380' },
    { Code: 'UG', Name: 'Uganda', Value: 'Uganda', phone: '256' },
    { Code: 'US', Name: 'United States', Value: 'United States', phone: '1', suggested: true },
    { Code: 'UY', Name: 'Uruguay', Value: 'Uruguay', phone: '598' },
    { Code: 'UZ', Name: 'Uzbekistan', Value: 'Uzbekistan', phone: '998' },
    { Code: 'VA', Name: 'Holy See (Vatican City State)', Value: 'Holy See (Vatican City State)', phone: '379' },
    { Code: 'VC', Name: 'Saint Vincent and the Grenadines', Value: 'Saint Vincent and the Grenadines', phone: '1-784' },
    { Code: 'VE', Name: 'Venezuela', Value: 'Venezuela', phone: '58' },
    { Code: 'VG', Name: 'British Virgin Islands', Value: 'British Virgin Islands', phone: '1-284' },
    { Code: 'VI', Name: 'US Virgin Islands', Value: 'US Virgin Islands', phone: '1-340' },
    { Code: 'VN', Name: 'Vietnam', Value: 'Vietnam', phone: '84' },
    { Code: 'VU', Name: 'Vanuatu', Value: 'Vanuatu', phone: '678' },
    { Code: 'WF', Name: 'Wallis and Futuna', Value: 'Wallis and Futuna', phone: '681' },
    { Code: 'WS', Name: 'Samoa', Value: 'Samoa', phone: '685' },
    { Code: 'XK', Name: 'Kosovo', Value: 'Kosovo', phone: '383' },
    { Code: 'YE', Name: 'Yemen', Value: 'Yemen', phone: '967' },
    { Code: 'YT', Name: 'Mayotte', Value: 'Mayotte', phone: '262' },
    { Code: 'ZA', Name: 'South Africa', Value: 'South Africa', phone: '27' },
    { Code: 'ZM', Name: 'Zambia', Value: 'Zambia', phone: '260' },
    { Code: 'ZW', Name: 'Zimbabwe', Value: 'Zimbabwe', phone: '263' },
  ];

export default CountriesList;
