import React, { useState, useContext } from "react";
import { Grid, Box, Button, Typography } from "@material-ui/core";
import WizardStep from "../../components/WizardStep/WizardStep";
import PageTitle from "../../components/PageTitle/PageTitle";
import { FormattedMessage } from "react-intl";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Redirect, useParams } from "react-router-dom";
import Lottie from "react-lottie";
import * as animationData from "../../assets/lottie/lf30_editor_3lwHSL.json";
import { GeneralContext } from "../../contexts/GeneralContext";

const Finish = () => {
  const { cid } = useParams();
  const { BookingReady, LngLink } = useContext(GeneralContext);
  const [RedirectOn, setRedirectOn] = useState();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData.default,
  };

  return (
    <Grid container>
      <Grid item xs={12} style={{ width: "100%" }}>
        <WizardStep activeStep={3} />
      </Grid>
      <Grid item xs={12}>
        <Box
          p={2}
          pt={0}
          pb={0}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Lottie
            options={defaultOptions}
            height={300}
            width={300}
            isStopped={false}
            isPaused={false}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <PageTitle
          pt={0}
          title="finish.title"
          description="finish.description"
        />
      </Grid>
      <Grid item xs={12}>
        <Box p={3} pt={0} pb={1}>
          <Typography variant="body2" align="center" color="primary">
            <FormattedMessage id="finish.body2" />
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} pt={1} pb={1}></Box>
      </Grid>
      <Grid item xs={12} className="ButtonWrapper">
        <Box p={2} pt={1} pb={4}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => setRedirectOn(true)}
            fullWidth
            endIcon={<ChevronRightIcon />}
          >
            <FormattedMessage id="finish.exit" />
          </Button>
        </Box>
      </Grid>
      {!BookingReady.find((cidN) => cidN == cid) || RedirectOn ? (
        <Redirect
          push
          to={`${LngLink + process.env.REACT_APP_ROOT_PATH_LINK}/`}
        />
      ) : null}
    </Grid>
  );
};

export default Finish;
