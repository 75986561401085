import React, { useContext, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  CardHeader,
  Avatar,
  CardMedia,
  Button,
  IconButton,
  Menu,
  MenuItem,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Divider
} from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import CheckIcon from "@material-ui/icons/Check";

import { FormattedMessage, FormattedDate } from "react-intl";
import { GeneralContext } from "../../contexts/GeneralContext";
import { picsUrl } from "../../helpers/helpers";
import moment from "moment";
import { Alert, AlertTitle } from "@material-ui/lab";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { toast } from "react-toastify";
import SanitizedHTML from "react-sanitized-html";
import forbiddenInstances from "../../constants/forbiddenInstances";

const BookingCard = props => {
  const {
    CIDData,
    ProductData,
    LngLink,
    PosData,
    cancelBookingFn,
    setCIDData
  } = useContext(GeneralContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loadingCancelBooking, setLoadingCancelBooking] = useState(false);
  const [OpenDialog, setOpenDialog] = useState(false);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const cancelBooking = () => {
    setLoadingCancelBooking(true);
    setOpenDialog(false);
    cancelBookingFn()
      .then(res => {
        console.log("cancelbooking res", res);
        toast.success(<FormattedMessage id="booking.cancelBookingSuccess" />);
        setCIDData({ ...CIDData, instancia: "noventa" });
      })
      .catch(err => {
        console.error("cancelbooking err", err);
        toast.error(<FormattedMessage id="booking.cancelBookingError" />);
      })
      .finally(() => {
        setLoadingCancelBooking(false);
      });
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const forbiddenInstance = forbiddenInstances.includes(CIDData.instancia);

  const cidPaxs = useMemo(() => {
    let paxs = !isNaN(Number(CIDData.paxs)) ? Number(CIDData.paxs) / 100 : 0;
    if (!paxs) {
      paxs = CIDData.adults + CIDData.childs + CIDData.babies;
    }
    return paxs;
  }, [CIDData.paxs, CIDData.adults, CIDData.childs, CIDData.babies]);

  return (
    <Box p={3}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container justify="space-between">
            <Grid item>
              {props.cancel || CIDData.instancia === "noventa" ? (
                <Typography variant="overline" color="textSecondary">
                  {CIDData.instancia === "noventa" ? (
                    <FormattedMessage id="bookingCard.cancelDone" />
                  ) : (
                    <FormattedMessage id="bookingCard.cancelPending" />
                  )}
                </Typography>
              ) : (
                <Typography variant="overline" color="textSecondary">
                  {CIDData.finish ? (
                    <FormattedMessage id="bookingCard.checkinReady" />
                  ) : (
                    <FormattedMessage id="bookingCard.checkinPending" />
                  )}
                </Typography>
              )}
            </Grid>
            {Number(CIDData.rooms) / 100 > 0 ? (
              <Grid item>
                <Typography variant="overline" color="textPrimary">
                  {Number(CIDData.rooms) / 100 > 1 ? (
                    <FormattedMessage
                      id="booking.roomsCount"
                      values={{ rooms: Number(CIDData.rooms) / 100 }}
                    />
                  ) : (
                    <FormattedMessage
                      id="booking.roomsCountSingular"
                      values={{ rooms: Number(CIDData.rooms) / 100 }}
                    />
                  )}
                </Typography>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Card elevation={3}>
            <CardHeader
              avatar={
                <Avatar
                  style={
                    CIDData.finish
                      ? {
                          border: "1px solid #4DD82433",
                          backgroundColor: "rgb(237, 247, 237)",
                          color: "#4DD824"
                        }
                      : {}
                  }
                >
                  {CIDData.finish ? <CheckIcon /> : <HourglassEmptyIcon />}
                </Avatar>
              }
              title={
                CIDData.nombre && CIDData.apellido ? (
                  `${CIDData.nombre} ${CIDData.apellido}`
                ) : (
                  <FormattedMessage id="booking.noName" />
                )
              }
              subheader={
                <FormattedMessage
                  id="bookingCard.cidNumber"
                  values={{ CID: CIDData.IDInbox }}
                />
              }
              action={
                CIDData.finish && !props.cancel ? (
                  <div>
                    <IconButton
                      aria-label="Profile"
                      aria-controls="booking-menu"
                      aria-haspopup="true"
                      onClick={handleClick}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      id="booking-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <Link
                        to={`${
                          LngLink + process.env.REACT_APP_ROOT_PATH_LINK
                        }/checkin/${CIDData.IDInbox}/paxs`}
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <MenuItem onClick={handleClose}>
                          <FormattedMessage id="booking.changeBooking" />
                        </MenuItem>
                      </Link>
                    </Menu>
                  </div>
                ) : null
              }
            ></CardHeader>
            <CardMedia
              image={picsUrl(
                ProductData.foto_tapa,
                window.innerWidth,
                window.innerWidth * 0.75
              )}
              title={ProductData?.additional_data?.title}
              style={{ height: 0, paddingTop: "56.25%" }}
            />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={6} md={3}>
                  <Box display="flex" flexDirection="column">
                    <Typography variant="overline" color="textSecondary">
                      <FormattedMessage id="booking.checkin" />
                    </Typography>
                    <Typography variant="body1">
                      <strong>
                        <FormattedDate
                          value={moment(CIDData.fehcaingreso)}
                          year="numeric"
                          month="long"
                          day="numeric"
                        />
                      </strong>
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Box display="flex" flexDirection="column">
                    <Typography variant="overline" color="textSecondary">
                      <FormattedMessage id="booking.checkout" />
                    </Typography>
                    <Typography variant="body1">
                      <strong>
                        <FormattedDate
                          value={moment(CIDData.fechaegreso)}
                          year="numeric"
                          month="long"
                          day="numeric"
                        />
                      </strong>
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Box display="flex" flexDirection="column">
                    <Typography variant="overline" color="textSecondary">
                      <FormattedMessage id="booking.stay" />
                    </Typography>
                    <Typography variant="body1">
                      <strong>
                        {CIDData.noches > 1 ? (
                          <FormattedMessage
                            id="booking.nightsCount"
                            values={{ nights: CIDData.noches }}
                          />
                        ) : (
                          <FormattedMessage
                            id="booking.nightsCountSingular"
                            values={{ nights: CIDData.noches }}
                          />
                        )}
                      </strong>
                    </Typography>
                  </Box>
                </Grid>
                {cidPaxs ? (
                  <Grid item xs={6} md={3}>
                    <Box display="flex" flexDirection="column">
                      <Typography variant="overline" color="textSecondary">
                        <FormattedMessage id="booking.paxs" />
                      </Typography>
                      <Typography variant="body1">
                        {cidPaxs ? (
                          <strong>
                            <FormattedMessage
                              id="booking.adults"
                              values={{ adults: cidPaxs }}
                            />
                          </strong>
                        ) : (
                          ""
                        )}
                      </Typography>
                    </Box>
                  </Grid>
                ) : (
                  ""
                )}

                {/* {"CIDData.policy: " + Boolean(CIDData.policy)}<br/>

                {"CIDData.policy.free_cancellation: " + (CIDData.policy && Boolean(CIDData.policy.free_cancellation))}<br/>

                {"moment().diff(moment(CIDData.policy.free_cancellation_until), 'days') <= 0: " +
                  (CIDData.policy && CIDData.policy.free_cancellation && moment().diff(moment(CIDData.policy.free_cancellation_until), "days") <= 0)}<br/>

                {"CIDData.instancia !== 'noventa': " + Boolean(CIDData.instancia !== "noventa")}<br/>

                {"CIDData.instancia !== 'nvcp': " + Boolean(CIDData.instancia !== "nvcp")}<br/>

                {"CIDData.mkt_via !== 'OTA': " + Boolean(CIDData.mkt_via !== "OTA")}<br/>

                {"!CIDData.codigo_extranet: " + Boolean(!CIDData.codigo_extranet)}<br/>

                {"CIDData.codigo_extranet - " + CIDData.codigo_extranet}<br/> */}

                {forbiddenInstance ? (
                  <Grid item xs={12}>
                    <Alert severity="error">
                      <AlertTitle>
                        <FormattedMessage id="booking.forbiddenInstance" />
                      </AlertTitle>
                      <FormattedMessage
                        id="booking.forbiddenInstanceDesc"
                        values={{
                          phone: (
                            <a href={`tel:${PosData.Phone}`} target="_blank">
                              {PosData.Phone}
                            </a>
                          )
                        }}
                      />
                    </Alert>
                  </Grid>
                ) : (
                  <>
                    {!props.cancel &&
                    CIDData.instancia !== "noventa" &&
                    CIDData.instancia !== "nvcp" ? (
                      <Grid item xs={12}>
                        {moment() <=
                          moment(CIDData.fehcaingreso).subtract(7, "days") &&
                        moment() <=
                          moment(CIDData.fehcaingreso).add(2, "days") &&
                        !CIDData.finish ? (
                          <Alert severity="warning">
                            <AlertTitle>
                              <FormattedMessage id="booking.tooEarly" />
                            </AlertTitle>
                            <FormattedMessage id="booking.tooEarlyDesc" />
                          </Alert>
                        ) : null}
                        {moment() >=
                          moment(CIDData.fehcaingreso).add(2, "days") &&
                        !CIDData.finish ? (
                          <Alert severity="error">
                            <AlertTitle>
                              <FormattedMessage id="booking.tooLate" />
                            </AlertTitle>
                            <FormattedMessage id="booking.tooLateDesc" />
                          </Alert>
                        ) : null}
                        {CIDData.finish ? (
                          <Alert severity="success">
                            <AlertTitle>
                              <FormattedMessage id="booking.finished" />
                            </AlertTitle>
                            <FormattedMessage id="booking.finishedDesc" />
                          </Alert>
                        ) : null}
                        {moment() >=
                          moment(CIDData.fehcaingreso).subtract(7, "days") &&
                        moment() <=
                          moment(CIDData.fehcaingreso).add(2, "days") &&
                        !CIDData.finish ? (
                          <Link
                            to={`${process.env.REACT_APP_ROOT_PATH_LINK}/checkin/${CIDData.IDInbox}/paxs`}
                            style={{ textDecoration: "none" }}
                          >
                            <Button
                              variant="contained"
                              color="primary"
                              size="large"
                              fullWidth
                              endIcon={<ChevronRightIcon />}
                            >
                              <FormattedMessage id="booking.startCheckin" />
                            </Button>
                          </Link>
                        ) : null}
                      </Grid>
                    ) : (
                      <Grid item xs={12}>
                        {CIDData.policy &&
                        CIDData.policy.free_cancellation &&
                        moment().diff(
                          moment(CIDData.policy.free_cancellation_until),
                          "days"
                        ) <= 0 &&
                        CIDData.instancia !== "noventa" &&
                        CIDData.instancia !== "nvcp" &&
                        CIDData.mkt_via !== "OTA" &&
                        (!CIDData.codigo_extranet ||
                          CIDData.codigo_extranet === "0" ||
                          CIDData.codigo_extranet === "NN") ? (
                          <Grid container spacing={1}>
                            <Grid item xs={12}>
                              <Alert severity="success">
                                <AlertTitle>
                                  <FormattedMessage id="booking.canCancel" />
                                </AlertTitle>
                                <FormattedMessage
                                  id="booking.canCancelDesc"
                                  values={{
                                    until: moment(
                                      CIDData.policy.free_cancellation_until
                                    ).format("LL")
                                  }}
                                />
                              </Alert>
                            </Grid>
                            <Grid item xs={12} className="ButtonWrapper">
                              <Box>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  size="large"
                                  fullWidth
                                  disabled={loadingCancelBooking}
                                  endIcon={<ChevronRightIcon />}
                                  onClick={handleClickOpen}
                                >
                                  <FormattedMessage id="booking.cancelBooking" />
                                </Button>
                                {loadingCancelBooking && (
                                  <CircularProgress
                                    size={24}
                                    className="ButtonProgress"
                                  />
                                )}
                              </Box>
                            </Grid>
                          </Grid>
                        ) : CIDData.instancia !== "noventa" &&
                          CIDData.instancia !== "nvcp" ? (
                          <Alert severity="warning">
                            <AlertTitle>
                              <FormattedMessage id="booking.cantCancel" />
                            </AlertTitle>
                            {CIDData.mkt_via === "OTA" ||
                            CIDData.codigo_extranet ? (
                              <FormattedMessage
                                id="booking.cantCancelDescOTA"
                                values={{
                                  phone: (
                                    <a
                                      href={`tel:${PosData.Phone}`}
                                      target="_blank"
                                    >
                                      {PosData.Phone}
                                    </a>
                                  )
                                }}
                              />
                            ) : CIDData.policy &&
                              CIDData.policy.free_cancellation_until ? (
                              <FormattedMessage
                                id="booking.cantCancelDesc"
                                values={{
                                  until: moment(
                                    CIDData.policy.free_cancellation_until
                                  ).format("LL"),
                                  phone: (
                                    <a
                                      href={`tel:${PosData.Phone}`}
                                      target="_blank"
                                    >
                                      {PosData.Phone}
                                    </a>
                                  )
                                }}
                              />
                            ) : (
                              <FormattedMessage
                                id="booking.cantCancelDescNoDate"
                                values={{
                                  phone: (
                                    <a
                                      href={`tel:${PosData.Phone}`}
                                      target="_blank"
                                    >
                                      {PosData.Phone}
                                    </a>
                                  )
                                }}
                              />
                            )}
                          </Alert>
                        ) : (
                          <Alert severity="error">
                            <AlertTitle>
                              <FormattedMessage id="booking.alreadyCancelled" />
                            </AlertTitle>
                            <FormattedMessage
                              id="booking.alreadyCancelledDesc"
                              values={{
                                cancelDate: CIDData.fecha_terminado
                                  ? moment(CIDData.fecha_terminado).format("LL")
                                  : moment().format("LL")
                              }}
                            />{" "}
                            {CIDData.policyInfo ? (
                              <Link onClick={handleClickOpen}>
                                <FormattedMessage id="booking.cantCancelLink" />
                              </Link>
                            ) : (
                              ""
                            )}
                          </Alert>
                        )}
                        <Dialog
                          open={OpenDialog}
                          keepMounted
                          fullWidth
                          maxWidth="sm"
                          onClose={handleCloseDialog}
                          aria-labelledby="alert-dialog-slide-title"
                          aria-describedby="alert-dialog-slide-description"
                        >
                          <DialogTitle id="alert-dialog-slide-title">
                            {
                              <FormattedMessage id="booking.cancelDialogTitle" />
                            }
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description">
                              {CIDData.policyInfo ? (
                                <div>
                                  {CIDData.instancia !== "noventa" &&
                                  CIDData.instancia !== "nvcp" &&
                                  CIDData.policy.free_cancellation_until ? (
                                    <Typography color="primary">
                                      <FormattedMessage id="booking.cancelDialogPrev" />
                                      <Box p={2}>
                                        <Divider variant="middle" />
                                      </Box>
                                    </Typography>
                                  ) : (
                                    ""
                                  )}
                                  <SanitizedHTML
                                    allowedAttributes={{ a: ["href"] }}
                                    allowedTags={[
                                      "a",
                                      "p",
                                      "strong",
                                      "b",
                                      "i",
                                      "br"
                                    ]}
                                    html={CIDData.policyInfo.espanol}
                                  />
                                </div>
                              ) : (
                                <FormattedMessage id="booking.cancelDialogDesc" />
                              )}
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button
                              autoFocus
                              onClick={handleCloseDialog}
                              color="default"
                            >
                              <FormattedMessage id="verify.close" />
                            </Button>
                            {CIDData.policy &&
                            moment().diff(
                              moment(CIDData.policy.free_cancellation_until),
                              "days"
                            ) <= 0 &&
                            CIDData.instancia !== "noventa" &&
                            CIDData.instancia !== "nvcp" ? (
                              <Button
                                onClick={() => cancelBooking()}
                                color="primary"
                              >
                                <FormattedMessage id="booking.confirmCancel" />
                              </Button>
                            ) : (
                              ""
                            )}
                          </DialogActions>
                        </Dialog>
                      </Grid>
                    )}
                  </>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BookingCard;
